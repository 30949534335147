import { Component,ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { EngagementKpiService } from '../../Services/engagement-kpi.service';
import { Dateformat,breadcrumnsCodes, CodeList, CodeListStatusTypes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { DatePipe } from '@angular/common';
import { Router ,ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
import { LoaderService } from 'src/app/Loader/loader.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CommonService } from 'src/app/shared/Service/common.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { EngagementKpi } from '../../Model/engagementKpi';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';


@Component({
  selector: 'app-eng-view-kpi',
  templateUrl: './eng-view-kpi.component.html',
  styleUrls: ['./eng-view-kpi.component.css']
})

export class EngViewKpiComponent {
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  editPrivilege:boolean=false;
  deletePrivilege:boolean=false;
  constructor(public privilegeService: PrivilegeService,private breadcrumbService: BreadcrumbService, private KpiService: EngagementKpiService,private datePipe: DatePipe,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private commonService:CommonService, private engagementService: EngagementService,private currencyService: CurrencyService,private masterDataService: SponserService,private supplierService: MasterSupplierService,private confirmationService: ConfirmationService,private messageService: MessageService,
    private auditLogService: AuditLogService,private tabAndStateService: TabAndStateService
  )
    {

    }
    @Output() editKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelKpitButtonClick = new EventEmitter<void>();
    @Input() engagementId: any;
    @Input() kpiId: any;
  codelist: any[] = [];
  _viewEngagementKPIDetails:any;
  _viewEngagementKPIHyperCareThresholdDetails:any[] =[];
  _viewEngagementKPIteadyStedyStateThresholdDetails:any[] =[];
  _matheMathicalData:any[] = [];
  _kpiDetails:any;
  _dateformart :any;
  _kPIDataCollectionGridBindDetails:any;
  checkBoxValue: boolean = true;
  breadcrumbs: MenuItem[]=[];
_enumBreadcrumb :any;
_engagementKPiId: any;
EngagementName:any;
selectedOption: string = 'Actions';
kpiReportDataCollection:any;
kpiCreatedUserName:string='';
kpiUpdatedUserName:string='';
createdBy:any;
codeListValues:any;
codeListStatusTypes:any;
listOfKpis:any;
currentIndex: number = -1; 
  ngOnInit() {
    this._dateformart=Dateformat;
    this._enumBreadcrumb=breadcrumnsCodes;     
    this._engagementKPiId =this.kpiId
    this.GetEngagementKPIDetailsById(this._engagementKPiId);
    this.BindPrivileges();
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.listOfKpis=this.tabAndStateService.getKpiData();
    this.currentIndex = this.listOfKpis.findIndex((a: { id: any; }) => a.id ==this._engagementKPiId);
    setTimeout(() => {
      this.calculateMitigationActionCounts();
    }, 1000);
  }
  BindPrivileges(){
    const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
   this.editPrivilege = hasPermission(Privileges.EditSupplierKPI);
   this.deletePrivilege=hasPermission(Privileges.DeleteEngagementKPI);

    }

    kpiStartFrequency:any;
    kpiEndFrequency:any;
    kpiDescription:any;
    findingsCounts: number[] = [];
 GetEngagementKPIDetailsById(id:any)  
 {
  this.loaderService.showLoader();
        this.KpiService.GetEngagementKPIDetailsByIdForViewPage(id).subscribe(res => {
      if(res.data.length > 0)
      {
        this.loaderService.hideLoader();
        this._viewEngagementKPIDetails =res.data[0];
        this._oldEngagementKpi= res.data[0];
        this._engagementKpi=this._viewEngagementKPIDetails;
        this.kpiDescription=this._viewEngagementKPIDetails.description ? this._viewEngagementKPIDetails.description.replace(/\n/g, "<br>") : this._viewEngagementKPIDetails.description;
      const engagementMitigationActions = this._viewEngagementKPIDetails.engagementMitigationActions || [];
      this.findingsCounts = [];
      this._viewEngagementKPIDetails.engagementKPIDataCollection.forEach(
        (reportItem: any, index: number) => {
          const selectedKpiDataCollectionId = reportItem.id;
    
          const matchingActions = engagementMitigationActions.filter(
            (action: any) => action.dataCollectionId === selectedKpiDataCollectionId
          );
    
          reportItem.findingsCount = matchingActions.length;
          this.findingsCounts[index] = matchingActions.length;
        }
      );
        this.kpiStartFrequency=this.commonService.getFrequencyFromDate(this._viewEngagementKPIDetails.kpiStartDate,this._viewEngagementKPIDetails.kpiDataCollectionFrequencyName);
        this.kpiEndFrequency=this.commonService.getFrequencyFromDate(this._viewEngagementKPIDetails.kpiEndDate,this._viewEngagementKPIDetails.kpiDataCollectionFrequencyName);

        this. _viewEngagementKPIHyperCareThresholdDetails = res.data[0].engagementKPIHyperCareThreshold != null? res.data[0].engagementKPIHyperCareThreshold:this. _viewEngagementKPIHyperCareThresholdDetails;
        this._viewEngagementKPIteadyStedyStateThresholdDetails = res.data[0].engagementKPISteadyStateThreshold;
        this._matheMathicalData = res.data[0].engagementMatheMaticSymbolsForHyperAndStreadCareThreshold;
        this._kpiDetails = this._viewEngagementKPIDetails.kpiDetails[0];

        this.kpiCreatedUserName=this.privilegeService.getUserName(res.data[0].createdBy);
        this.kpiUpdatedUserName=this.privilegeService.getUserName(res.data[0].updatedBy);
        this._kPIDataCollectionGridBindDetails = this._viewEngagementKPIDetails.engagementDataCollection;             
        this.kpiReportDataCollection=res.data[0].engagementReportDataCollectionModelList;
        
        this.HyperCareAndSteadyStateThresholdData();
        this.GetEngagementDataById(this.engagementId);
        this.SetFrequencyValueforDataCollection();
      }
    });
  }


  SetFrequencyValueforDataCollection(){
       
    this.kpiReportDataCollection.forEach((item:any) => {
      item.kpiDataCollectionModelList.forEach((collectionRow:any) => {
        collectionRow.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(collectionRow.dataCollectionDate,this._viewEngagementKPIDetails.kpiDataCollectionFrequencyName);
      });
     
    });
  }


  HyperCareAndSteadyStateThresholdData()//Mapping MatheMatical Symbols by with hyperCareSteadyStateThreshold Data
  {  
  let hyperCare = 0; 
this._viewEngagementKPIHyperCareThresholdDetails.forEach((kPIHyperDetails: any) => {  
    const filteredSymbol = this._matheMathicalData.filter((matheDetails: any) => matheDetails.id === kPIHyperDetails.mathematicalSymbol);   

    if (filteredSymbol.length > 0) {   
        this._viewEngagementKPIHyperCareThresholdDetails[hyperCare].mathematicalSymbol = filteredSymbol[0].symbolName.split(' ')[0];
    }    
  
    hyperCare++;
});
let steadyState = 0; 

this._viewEngagementKPIteadyStedyStateThresholdDetails.forEach((kPISteadyStateThresholdDetails: any) => {  
    const filteredSymbol = this._matheMathicalData.filter((matheDetails: any) => matheDetails.id === kPISteadyStateThresholdDetails.mathematicalSymbol);   

    if (filteredSymbol.length > 0) {   
        this._viewEngagementKPIteadyStedyStateThresholdDetails[steadyState].mathematicalSymbol = filteredSymbol[0].symbolName.split(' ')[0];
    }    
  
    steadyState++;
});

  }
  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
  {
   
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
  }
  
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }

  onOptionChange(): void {//Click on Actions button navigate to edit  
      if (this.selectedOption === 'Edit') {
        const data = {
          engagementId:this.engagementId,
          kpiId:this.kpiId
        };
        this.editKpiButtonClick.emit(data);
      }
      
      if(this.selectedOption === 'Delete' && this.kpiId){
        this.confirmationService.confirm({
          message: `Are you sure you want to delete this KPI?`,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
          this.KpiService.DeleteEngagementKpi(this.kpiId).subscribe(res => {
            if(res.data > 0)
            {
              this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: `KPI is deleted successfully`,
              detail: '',
          });
              this.compareModels();
              this.cancelKpitButtonClick.emit();
            }
        });
             },
             reject: () => {
              this.selectedOption = 'Actions';
             },
           });       
      }
  }
  BackToList()
  {
    this.cancelKpitButtonClick.emit();
  }

  GetEngagementDataById(EngagementId:number){

    this.engagementService.GetEngagementById(EngagementId).subscribe(
      res=>{
        this.EngagementName=res.data.engagementName;
        this.getCombinedContacts(res.data.supplierId,res.data.sponsorId);
       
      }
    );
  }
  
  currencies: any='USD';
  getFormattedValue(value: number, currencyName: string): string {
  let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
 return this.handleCurruncy(formattedValue);
 }
 private handleCurruncy(formattedValue: string): string {
   return formattedValue.replace(/^[^\d]+/, '');
}
findingsCount:any;
displayMitigationListPopup: boolean = false;
displayViewMitigationPopup: boolean = false;
position: string = 'center';
selectedKpiReportData: any;
mitigationActions: any[] = [];

viewFindingList(index: number, kpiReportDataCollection: any) 
{
  this.selectedKpiReportData=kpiReportDataCollection.kpiDataCollectionModelList[index];
  const selectedKpiDataCollectionId = this.selectedKpiReportData.id;

  if (this._viewEngagementKPIDetails.engagementMitigationActions) {
    const selectedMitigationActions = this._viewEngagementKPIDetails.engagementMitigationActions.filter(
      (action: any) => action.dataCollectionId === selectedKpiDataCollectionId
    );
    this.mitigationActions = selectedMitigationActions;
  }
  this.displayMitigationListPopup = true;
}

cancelDialog(){
  this.displayMitigationListPopup = false;
}
actionStatus:any;
GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
  this.masterDataService.getCodeListData(enumId, type).subscribe(
    res => {      
      if (res.data.length > 0) {
        this.actionStatus = res.data;
      }
    }
  );
};
getStatusName(statusId: number): string {
  const status = this.actionStatus.find((s: any) => s.id === statusId);
  return status ? status.name : '--';
}
supplierContactsDataSource : SupplierContact[]=[];
sponsorContactsDataSource : SponsorContact[]=[];
combinedContacts: any[] = [];
getCombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
  this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
      this.supplierContactsDataSource = supplierRes.data.supplierContacts;
      this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
        this.sponsorContactsDataSource = sponsorRes.data.sponserContacts.map((contact: { id: any; name: any; countryCode: any; designation: any; phoneNumber: any; email: any; sponserId: any; createdBy: any; createdDate: any; updatedBy: any; updatedDate: any; isDeleted: any; }) => {
          return {
              id: contact.id,
              contactName: contact.name, 
              countryCode: contact.countryCode,
              designation: contact.designation,
              phoneNumber: contact.phoneNumber,
              email: contact.email,
              supplierProfileId: contact.sponserId, 
              createdBy: contact.createdBy,
              createdDate: contact.createdDate,
              updatedBy: contact.updatedBy,
              updatedDate: contact.updatedDate,
              isDeleted: contact.isDeleted
          };
      });
          this.combinedContacts = [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
      });
  });
}
getContactName(actionOwnerId: number): string {
  const contact = this.combinedContacts.find((c: any) => c.id === actionOwnerId);
  return contact ? contact.contactName : '--';
}
navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfKpis.length) {
    this._engagementKPiId=this.listOfKpis[index].id;
    this.kpiId=this.listOfKpis[index].id;
    this.GetEngagementKPIDetailsById( this._engagementKPiId);
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfKpis.length}`;
}


mitigationActionCounts:any;
calculateMitigationActionCounts() {
  const actionCount = new Map<number, number>();

  if (this._viewEngagementKPIDetails?.engagementKPIDataCollection) {
    this._viewEngagementKPIDetails.engagementKPIDataCollection.forEach((dataCollection: { id: number }) => {
      const mitigationActions = this._viewEngagementKPIDetails.engagementMitigationActions?.filter(
        (action: { dataCollectionId: number }) => action.dataCollectionId === dataCollection.id
      ) || [];
      actionCount.set(dataCollection.id, mitigationActions.length);
    });
  }

  this.mitigationActionCounts = actionCount;
}
getMitigationActionCount(dataCollectionId: number): number {
  return this.mitigationActionCounts.get(dataCollectionId) || 0;
}
_engagementKpi=new EngagementKpi();
changedEngagementKPIBasicInfo:any;
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
_oldEngagementKpi: any;
compareModels() {
  this._oldEngagementKpi = JSON.parse(JSON.stringify(this._oldEngagementKpi));
  this._engagementKpi.isDeleted = true;
  this.changedEngagementKPIBasicInfo = this.auditLogService.compareModels((this._oldEngagementKpi),this._engagementKpi,this.auditEntities.EngagementKPIs,this.auditIdentifierDetails.DeleteEngagementKPI,this.auditOperations.Delete,this.auditModule.GovernanceService);
}
}
