import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, breadcrumnsCodes, CodeList, CodeListStatusTypes, Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';

import { GovernanceService } from '../../../Engagements/Services/governance.service';
import { ContactMeetingContacts, ContractAddMeetingOccurenceActions, ContractAddMultipleOccurrences, ContractMeetingAbsentees, ContractMeetingAttendees, ContractMeetingDetails, ContractMeetingOccurrenceAttachments, ContractMeetingOccurrences } from '../../Modals/ContractMeeting';
import { AssociateService } from 'src/app/Team/teams/Services/associate.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';

@Component({
  selector: 'app-con-edit-meeting',
  templateUrl: './con-edit-meeting.component.html',
  styleUrls: ['./con-edit-meeting.component.css']
})
export class ConEditMeetingComponent {
  isSaveBtnDisable = false;
  @Input() ContractId: any;
  @Input() meetingId: any;
  @Input() EngagementId: any;
  @Input() supplierName: any;
  @Output() cancelMeetingButtonClick = new EventEmitter<void>();
  @Output() saveMeetingButtonClick = new EventEmitter<void>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  isRecurringNo: boolean = false;
  AddMultipleOccurrences: any[] = [];
  value!: string;
  addMeetingOccurenceDate: Date | undefined;
  organizerOptions: any[] = [];
  contractData: any;
  contactData: any;
  DisplayAddOccurrencesPopUP: boolean = false;
  contractMeetingId: any;
  attendeesValues: any = '';
  absenteesValues: any = '';
  meetingStatus: any[] = [];
  _dateformart: any;
  addMeetingActionsData: any[] = [];
  uploadedFiles: File[] = [];
  _uploadFileForEachOccurrences: File[] = [];
  maxFileSize: any
  timeStamp: any;
  addMeetingSaved = false;
  currentFileSize: any;
  multiMeetingFileData: any[] = [];
  uploadedFileName: any;
  allowedExtensions: any;
  uploadedMeetingFile: any;
  newAttachment: any;
  submitted: boolean = false;
  attndees: any;
  absentees:any;
  selectedTitle:any='';
  MeetingData: any[] = [];
  uploadedFileNames: string = "";
  _enumBreadcrumb: any;
  codeListValues:any;
  codeListStatusTypes:any;
  _addActionsFieldsMandatory: boolean = false;
  isUploadButtonDisabled:boolean= false;
  _finalAttendees:any;
  formData = new FormData();
  supplierAddMeetingForm: FormGroup;
  _contractmeetingActions = new ContractAddMeetingOccurenceActions();
   contractMeetingDetails = new ContractMeetingDetails();
  _contractMeetingOccurrenceDetails: ContractMeetingOccurrences[] = [];
  _contractMeetingAttendeesDetails: ContractMeetingAttendees[] = [];
  _contractMeetingAbsenteesDetails: ContractMeetingAbsentees[] = [];
  _ContractMeetingActionsDetails: ContractAddMeetingOccurenceActions[] = [];


  _contractMeetingOccurrenceAttachments = new ContractMeetingOccurrenceAttachments();
  _contractMeetingAttendees = new ContractMeetingAttendees();
  _contractMeetingAbsentees = new ContractMeetingAbsentees();
  _contractMeetingOccurrences = new ContractMeetingOccurrences();
  _ContractAddOccurrences = new ContractAddMultipleOccurrences();
  _contractMeetingOccurrenceAttachmentsDetails: any[] = [];
  _ContractAddMultipleOccurrences: ContractAddMultipleOccurrences[] = [];
  _contractMeetingAbsenteesarray: ContractMeetingAbsentees[] = [];
  auditIdentifierDetails:any;
  contactFormGroup:FormGroup;
  constructor(
    private governanceService: GovernanceService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private masterDataService: SponserService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _fileService: FileUploadService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private checkNullOrEmptyValuesService: ChecknulloremptyComponent,
      private _associateService: AssociateService,

      private auditLogService: AuditLogService ,
      private privilegeService:PrivilegeService,
      private _mastersupplierService: MasterSupplierService,
      private engagementService: EngagementService

      )

   {
        this.supplierAddMeetingForm = this.fb.group({
      addMeetingTitle: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3), this.noOnlySpacesValidator]],
      addMeetingOrganizer: ['', [Validators.required]],
      addMeetingRecurring: ['', [Validators.required]],
      addMeetingOccurenceDate: ['', [Validators.required]],
      addMeetingFileSource: [''], addMeetingFileName: '',
      addMeetingAttachments: [''],
      addMeetingAgenda: ['', [Validators.required]],
      addMeetinMeetingNotes: [''],
      addMeetingDecisions: [''],
      addMeetingAttendees:[''],
      addMeetingAbsentees:[''],
      _attendent: [''],
      _absent: [''],
    });
    this.contactFormGroup=this.fb.group({
      contractName:['',[Validators.required,Validators.maxLength(30),Validators.minLength(3),Validators.pattern(/^[A-Za-z- ]{3,30}$/)]],
    });
  }
 
  contractDetailId:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;
   MeetingCreatedByUserName:string='';
   MeetingUpdatedByUserName:string='';
   contactName: string = '';
  ngOnInit() {
    this._dateformart = Dateformat;
    this.contractDetailId =this.ContractId ;
    this.contractMeetingId = this.meetingId;
   
    this._enumBreadcrumb=breadcrumnsCodes;
    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
    this.GetEngagementDetailsById();
    this.GetContractMeetingById(this.contractMeetingId);
    this.formOldContractMeeting();
    this.auditIdentifierDetails= AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
  }

  sponserId:number=0;
  engagementData:any;
  GetEngagementDetailsById() {
 
    this.engagementService.GetEngagementById(this.EngagementId)
      .subscribe(res => {    
        this.engagementData = res.data;
        this.sponserId=this.engagementData.sponsorId;
        this.GetSupplierContacts(res.data.supplierId);
        this.GetSponserDetailsById();
      });
  }
  GetSupplierContacts(Id:number){
 
    this._mastersupplierService.getSupplierContactsDataById(Id)
    .subscribe(res=>{
      this.contactData = res.data;
    })
  }
  sponserData:any;
  sponsorContactData:any;
    GetSponserDetailsById(){
     this.masterDataService.getSponserData(this.sponserId)
     .subscribe(res=>{
       this.sponserData=res.data.sponserInfo;
       this.sponsorContactData=res.data.sponserContacts;
       this.GetSupplierTeamAssociatesList(this.EngagementId,2)
       this.GetTeamAssociatesList(this.ContractId,1)
     })
    }
    supplierassociateData:any;
    supplierTeams:any;
    GetSupplierTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
     
      this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
        if(res.status){
          this.GetTeamAssociatesList(this.ContractId,1)
        this.supplierassociateData=res.data;
        this.supplierassociateData = this.supplierassociateData?.filter((t:any) => t.contractName === 'Not Applicable');
        this.supplierTeams = this.supplierassociateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
        }
      });
    }

    associateData:any;
    GetTeamAssociatesList(conractIdOrSupplierId:any,teamType:number){
     
      this._associateService.GetTeamAssociatesList(conractIdOrSupplierId,teamType).subscribe((res:any)=>{
        if(res.status){
        this.associateData=res.data;
        this.updateOrganizerOptions();
        }
      });
    }


    updateOrganizerOptions() {
      if (this.engagementData !== undefined && this.contactData !== undefined) {
        const engagementOptions = this.contactData.map((contact: { contactName: any; }) => `${contact.contactName} ( ${this.supplierName} )`);
        if (!this.organizerOptions) {
          this.organizerOptions = [];
        }
        if(this.attndees!=undefined){
        this.attndees = this.attndees.split(',').map((item: string) => item.trim());
        }
        if(this.absentees!=undefined){
        this.absentees = this.absentees.split(',').map((item: string) => item.trim());
        }
        if(this.organizerOptions!=undefined){
          this.organizerOptions = this.organizerOptions.concat(engagementOptions);          
          }
          if(this.attndees!=undefined){
            this.attndees=this.attndees.concat(engagementOptions);
          }
          if(this.absentees!=undefined){
            this.absentees=this.absentees.concat(engagementOptions);
          }
        this._allAttendees=this._allAttendees.concat(engagementOptions);

             }
      if (this.sponserData !== undefined && this.sponsorContactData !== undefined) {
        const sponsorOptions = this.sponsorContactData.map((contact: { name: any; }) => `${contact.name} ( ${this.sponserData.name} )`);
        if (!this.organizerOptions) {
          this.organizerOptions = [];
        }
        if(this.organizerOptions!=undefined){
          this.organizerOptions = this.organizerOptions.concat(sponsorOptions);          
          }
          if(this.attndees!=undefined){
            this.attndees=this.attndees.concat(sponsorOptions);
          }
          if(this.absentees!=undefined){
            this.absentees=this.absentees.concat(sponsorOptions);
          }
        this._allAttendees=this._allAttendees.concat(sponsorOptions);
      }
       //added by vittal for associates
       if (this.associateData !== undefined && this.associateData !== null) {
        const associateOptions = this.associateData.map((contact: { associateName: any; }) => `${contact.associateName} ( Associate )`);
        if (!this.organizerOptions) {
          this.organizerOptions = [];
        }
        if(this.organizerOptions!=undefined){
        this.organizerOptions = this.organizerOptions.concat(associateOptions);
        }
        if(this.attndees!=undefined){
        this.attndees=this.attndees.concat(associateOptions);
         this._attendees.push(this.contractMeetingDetails.Organizer);
        }
        if(this.absentees!=undefined){
        this.absentees=this.absentees.concat(associateOptions);
        }
        if(this._allAttendees!=undefined){
        this._allAttendees=this._allAttendees.concat(associateOptions);
        }
      }
       //added by nagaraju for ContractMeetingContacts
        if (this.meetingContacts !== undefined && this.meetingContacts !== undefined) {
        const meetingContactsOptions = this.meetingContacts.map((contact: { contactName: any; }) => `${contact.contactName}`);
        if (!this.organizerOptions) {
          this.organizerOptions = [];
        }
        if(this.organizerOptions!=undefined){
          this.organizerOptions = this.organizerOptions.concat(meetingContactsOptions);
          
          }
          if(this.attndees!=undefined){
            this.attndees=this.attndees.concat(meetingContactsOptions);
          }
          if(this.absentees!=undefined){
            this.absentees=this.absentees.concat(meetingContactsOptions);
          }
        this._allAttendees=this._allAttendees.concat(meetingContactsOptions);
         this.loaderService.hideLoader();
         this.loaderService.hidefinalLoader();
      }
      let attendeesObjects: { name: string; code: number }[] = [];
      if (this._allAttendees.length > 0) {
          attendeesObjects = this._allAttendees.map((attendee, index) => ({
              name: attendee,
              code: index + 1, 
          }));
    }
    this._finalAttendees=attendeesObjects;
    this._allAttendees = attendeesObjects.map((attendee) => attendee.name);
  
    if (this.attendeesValues.length > 0) {
      this.attendeesValues = this._finalAttendees.filter((attendee: { name: any; }) => this.attendeesValues.includes(attendee.name));
    } 
  
    if (this.absenteesValues.length > 0) {
      this.absenteesValues = this._finalAttendees.filter((absentee: { name: any; }) => this.absenteesValues.includes(absentee.name));
    }
    if (!this.organizerOptions.includes(this.contractMeetingDetails.Organizer)) {
      this.organizerOptions.push(this.contractMeetingDetails.Organizer);
    }
    }
    OrganizeOptionsList(existingValues: string[], options: string[]) {
      existingValues.forEach(value => {
        if (!options.includes(value)) {
          options.push(value);
        }
      });
    }
    
    contractsList: string[] = [];
    myaction:any;
    attachments:any;
    oldattendees:any;
    oldabsentees:any;
    oldactions:any;
    meetingContacts:any;
    GetContractMeetingById(contractMeetingId: any) {
      this.loaderService.showLoader();
      this.governanceService.getContractMeetingsById(contractMeetingId)
        .subscribe(res => {
          if (res != null) {
            this.contractMeetingDetails.Title = res.data.title;
            this.selectedTitle=this.contractMeetingDetails.Title;
            this.contractMeetingDetails.Organizer = res.data.organizer;
            this.contractMeetingDetails.Recurring = res.data.recurring;
            if (this.contractMeetingDetails.Recurring == false) {
              this.isRecurringNo = true
            }
           this.meetingContacts=res.data.contractMeetingOccurrences[0].contractMeetingContacts;
            this._contractMeetingOccurrences.OccurrenceDate = new Date(res.data.contractMeetingOccurrences[0].occurrenceDate);
            this.contractMeetingDetails.Agenda = res.data.agenda;
            this.contractMeetingDetails.CreatedDate=res.data.createdDate;
            this.MeetingCreatedByUserName=this.privilegeService.getUserName(res.data.createdBy);
            this.MeetingUpdatedByUserName=this.privilegeService.getUserName(res.data.updatedBy);
            this.contractMeetingDetails.UpdatedDate=res.data.updatedDate;
            
            if( res.data.contractMeetingOccurrences[0].contractMeetingAttendees[0]!=undefined){
              this._contractMeetingAttendees.Attendees = res.data.contractMeetingOccurrences[0].contractMeetingAttendees[0].attendees.split(',');
              this.attndees = res.data.contractMeetingOccurrences[0].contractMeetingAttendees[0].attendees.split(',').join(',');
              this._contractMeetingAttendees.Id = res.data.contractMeetingOccurrences[0].contractMeetingAttendees[0].id;
              this.attendeesValues=this.attndees;
              this.attendeesValues=this.attendeesValues ? this.attendeesValues.split(',') : [];
              this.oldattendees=this.attendeesValues;
            }
            if( res.data.contractMeetingOccurrences[0].contractMeetingAbsentees[0]!=undefined){
              this._contractMeetingAbsentees.Absentees = res.data.contractMeetingOccurrences[0].contractMeetingAbsentees[0].absentees.split(',');
              this.absentees = res.data.contractMeetingOccurrences[0].contractMeetingAbsentees[0].absentees.split(',').join(',');
              this._contractMeetingAbsentees.Id = res.data.contractMeetingOccurrences[0].contractMeetingAbsentees[0].id;
              this.absenteesValues=this.absentees;
              this.absenteesValues=this.absenteesValues ? this.absenteesValues.split(',') : []; 
              this.oldabsentees=this.absenteesValues;
            }
            this.myaction= res.data.contractMeetingOccurrences[0].contractMeetingOccurrenceActions;
           setTimeout(() => {
            this.ExistingActions();
           }, 2000);
            this.oldactions=this.myaction;
            this._contractMeetingOccurrences.MeetingNotes = res.data.contractMeetingOccurrences[0].meetingNotes;
            this._contractMeetingOccurrences.Decisions = res.data.contractMeetingOccurrences[0].decisions;
            this._contractMeetingOccurrences.CreatedDate=res.data.contractMeetingOccurrences[0].createdDate;
            this._contractMeetingOccurrences.ContractMeetingId=res.data.contractMeetingOccurrences[0].contractMeetingid;
            this._contractMeetingOccurrences.Id = res.data.contractMeetingOccurrences[0].id;
            this._contractMeetingOccurrences.ContractMeetingId =res.data.contractMeetingOccurrences[0].contractMeetingId ;

            if(res.data.contractMeetingOccurrences[0].contractMeetingOccurrenceAttachments.length>0){

            this._contractMeetingOccurrenceAttachments.Id = res.data.contractMeetingOccurrences[0].contractMeetingOccurrenceAttachments[0].id;
            this._contractMeetingOccurrenceAttachmentsDetails= res.data.contractMeetingOccurrences[0].contractMeetingOccurrenceAttachments;
            this.uploadedFiles = res.data.contractMeetingOccurrences[0].contractMeetingOccurrenceAttachments.map((attachment: { fileName: any; }) => {
              return new File([attachment.fileName], attachment.fileName);
            });
  
            this.uploadedFileNames = this.uploadedFiles.map(file => file.name).join(', ');
            if(this.uploadedFiles.length >= 3)
            {
              this.isUploadButtonDisabled = true;
            }
            else{
              this.isUploadButtonDisabled = false;
            }
          }
       }      
    this.oldContractMeetingData=JSON.stringify(this.contractMeetingDetails);
    this.contractMeetingDetailBeforeUpdate=JSON.stringify(this.contractMeetingDetails);
  
          this.oldContractMeetingData = JSON.stringify({
         ...JSON.parse(this.oldContractMeetingData),
          ContractMeetingOccurrences: this._contractMeetingOccurrences
        });
  
          this.contractMeetingDetailBeforeUpdate = JSON.stringify({
          ...JSON.parse(this.contractMeetingDetailBeforeUpdate),
          ContractMeetingOccurrences: this._contractMeetingOccurrences
          });
  
        })
        
    }        
    AddMeetingActions() {
      // If the array is not empty, add a new empty row
      this.addMeetingActionsData.unshift({
        Id: 0,
        actionName: '',
        owner: '',
        targetDate: null,
        statusMappingId: '',
        closureDate: null,
        remarks: '',
        contractMeetingOccurrenceId: 0,
        isDeleted: false,
        createdDate:null,
        updatedBy:null,
      });
   
  }
    deleteAction(currentActionIndex: number) {  
      this.confirmationService.confirm({
           header: 'Confirm',
           message: 'Are you sure you want to delete this action?',
           accept: () => {
           this.addMeetingActionsData.splice(currentActionIndex, 1);
           this.messageService.add({ severity: 'success', summary: 'Meeting Action Deleted', detail: '', });
           }
         });
       }
       GetMeetingStatus(enumId: number, type: string) { 
        this.masterDataService.getCodeListData(enumId, type).subscribe(
          res => {      
            if (res.data.length > 0) {
              this.meetingStatus = res.data;
              
            }
          }
        );
      };

      
    ExistingActions(){
       if (this.addMeetingActionsData.length === 0) {
        for (const action of this.myaction) {  
         if(action.closureDate!=null)
         {
            action.closureDate = new Date(action.closureDate);
         }
         if(action.targetDate!=null)
         {
            action.targetDate = new Date(action.targetDate);
         }
          action.statusMappingId=this.meetingStatus.find(status => status.id === action.statusMappingId);
          this.addMeetingActionsData.push({
            Id: action.id,
            actionName: action.actionName,
            owner: action.owner,
            targetDate: action.targetDate,
            statusMappingId: action.statusMappingId,
            closureDate: action.closureDate,
            remarks: action.remarks,
            contractMeetingOccurrenceId: action.contractMeetingOccurrenceId,
            isDeleted: false,
            createdDate:action.createdDate,
            createdBy:action.createdBy,
            updatedBy:action.updatedBy
            
          });
          
        }
      }
    }
    options: any[] = [
      { name: 'Yes', value: true }, // Adjust these options as needed
      { name: 'No', value: false },
    ];
    onRecurringChange(event: any) {
      this.contractMeetingDetails.Recurring = event.value;
    }
 

  executeCanExist: boolean = true;
  _oldContractMeeting: any
  canExist(): boolean | Promise<boolean> | Observable<boolean> { 
    if (this.executeCanExist && ((!(this._oldContractMeeting==JSON.stringify(this.contractMeetingDetails))) || (!(this._oldContractOccurences==JSON.stringify(this._contractMeetingOccurrences))) || (!(this._oldContractAttendees==JSON.stringify(this._contractMeetingAttendees))) || (!(this._oldContractAbsentees==JSON.stringify(this._contractMeetingAbsentees))) || (!(this._oldContractAttachments==JSON.stringify(this._contractMeetingOccurrenceAttachments))) || (!(this._oldContractActions==JSON.stringify(this._contractmeetingActions))) )) {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes Not Saved',
              detail: '',
            });
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      });
    }
    else {
      return true;
    }

  }
  
  onCancelContractMeeting()
  {
    const dataResult = {
      contractid:this.contractDetailId,
      meetingId:this.meetingId,
    };
    if ((!(this._oldContractMeeting==JSON.stringify(this.contractMeetingDetails))) || (!(this._oldContractOccurences==JSON.stringify(this._contractMeetingOccurrences))) || (!(this._oldContractAttendees==JSON.stringify(this._contractMeetingAttendees))) || (!(this._oldContractAbsentees==JSON.stringify(this._contractMeetingAbsentees))) || (!(this._oldContractAttachments==JSON.stringify(this._contractMeetingOccurrenceAttachments))) || (!(this._oldContractActions==JSON.stringify(this._contractmeetingActions)))) {
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelMeetingButtonClick.emit();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
           },
           reject: () => {
           },
         });
     }
     else{
       this.cancelMeetingButtonClick.emit();
     }
  }
  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length <= 1) {
      this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
      if (files.length > 0) {
        this.currentFileSize = files[0].size;
        this.uploadedFileName = files[0].name;
        this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX,FileExtension.PPT,FileExtension.PPTX];
        var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
      }
      // Check if the uploaded file's extension is allowed
      if (this.allowedExtensions.includes(uploadedExtension)) {
        if (this.currentFileSize <= this.maxFileSize) {
          if (this.uploadedFiles.length + files.length <= 3) {
            for (let i = 0; i < files.length; i++) {
              this.uploadedFiles.push(files[i]);
              this.supplierAddMeetingForm.patchValue({
                addMeetingAttachments: files[0].name,
              });
              this.uploadedMeetingFile = files[0];
              this.multiMeetingFileData.push(this.uploadedMeetingFile);
             this.timeStamp = "";
             var utcDateTimeNow = new Date().toISOString();
             this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
             //this.formData.append("files[]", file, fileName);
              this.formData.append("file", this.uploadedMeetingFile, "Governance/ContractMeeting/" + this.timeStamp + "_" + this.uploadedMeetingFile.name);
              this._contractMeetingOccurrenceAttachments.FileName = this.uploadedMeetingFile.name;
              this._contractMeetingOccurrenceAttachments.FilePath = "Governance/ContractMeeting/" + this.timeStamp + "_" + this._contractMeetingOccurrenceAttachments.FileName;
             
              this.newAttachment = {
                      FileName: this._contractMeetingOccurrenceAttachments.FileName,
                      FilePath: this._contractMeetingOccurrenceAttachments.FilePath
                    };
              this._contractMeetingOccurrenceAttachmentsDetails.push(this.newAttachment);
            }
          } else {
            this.supplierAddMeetingForm.patchValue({
              addMeetingFileName: '',
              addMeetingFileSource: ''

            });

            this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
          }
        }
        else {
          this.supplierAddMeetingForm.patchValue({
            addMeetingFileName: '',
            addMeetingFileSource: ''
          });
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
        }
      }
      else {
        this.supplierAddMeetingForm.patchValue({
          addMeetingFileName: '',
          addMeetingFileSource: ''
        });
        this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
      }

    }
    else {
      this.supplierAddMeetingForm.patchValue({
        addMeetingFileName: '',
        addMeetingFileSource: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
  deleteUploadedFiles: any[] = [];
  removeFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);

      const attachmentIndex = this._contractMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
      if (attachmentIndex !== -1) {
        this._contractMeetingOccurrenceAttachmentsDetails.splice(attachmentIndex, 1);
      }
      this._contractMeetingOccurrenceAttachmentsDetails = this._contractMeetingOccurrenceAttachmentsDetails.map(e => {
        return this.convertKeysToUppercase(e);
    });
    var isOldOccurrenceAttachement = this._contractMeetingOccurrenceAttachmentsDetails.some(item => item.hasOwnProperty('Id'));
    if(isOldOccurrenceAttachement){
      const editOccurremceAttachment= this._contractMeetingOccurrenceAttachmentsDetails.findIndex(s => s.FileName == file.name && s.Id != 0);
      if (editOccurremceAttachment !== -1) {    
        var filedetails = {
          "fileName":  this._contractMeetingOccurrenceAttachmentsDetails[editOccurremceAttachment].FileName,
          "filePath":  this._contractMeetingOccurrenceAttachmentsDetails[editOccurremceAttachment].FilePath
        };
        if (filedetails.fileName != "" && filedetails.filePath != "") {                    
              this.deleteUploadedFiles.push(filedetails);    
              this._contractMeetingOccurrenceAttachmentsDetails.splice(editOccurremceAttachment,1)                          
          }
      }   
    }
    else{
      if(this._contractMeetingOccurrenceAttachmentsDetails)
      {
        const index = this._contractMeetingOccurrenceAttachmentsDetails.findIndex(attachment => attachment.FileName === file.name);
        if (index !== -1) {      
          this._contractMeetingOccurrenceAttachmentsDetails.splice(index,1)
      }     
      }
    }

    }
    if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
  }
  convertKeysToUppercase(obj: any): any {
    const convertedObject: any = {};
  
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const uppercaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            convertedObject[uppercaseKey] = obj[key];
        }
    }
  
    return convertedObject;
  }
  UpdateMeetingDetails()//click on save button in edit meeting screen
  {
    this.loaderService.showLoader();
    this.addMeetingSaved=true; 
    this.isSaveBtnDisable = true; 
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    this._addActionsFieldsMandatory = this.addMeetingActionsData.some((Actions) => {
      if (
          this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.actionName) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.statusMappingId) || (Actions.statusMappingId.code === "CLD" &&
              (this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.closureDate) || this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(Actions.remarks)))
      ) {
          return true;
      }
      return false;
  }); 
    if (this.supplierAddMeetingForm.valid && !this._addActionsFieldsMandatory) { 
      this.loaderService.showLoader(); 
      this.executeCanExist=false;
      this.loaderService.showLoader();
      if (this.deleteUploadedFiles.length > 0 ) {
        this.deleteOccurrenceAttachment();
      }
    if (this.multiMeetingFileData.length > 0) {
      this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {
        if (response) {
          this.updateMeetingData();
        }
        else {
          this.loaderService.hideLoader();
          this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add Meeting File Failed to save', detail: '' });
        }
      })
    }
    else {
      this.updateMeetingData();
    }
  }else {
    this.loaderService.hideLoader();
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
  }
  }

  deleteOccurrenceAttachment(){
    this._fileService.DeleteFile(this.deleteUploadedFiles).subscribe(
      res => {
    
        
      }
    );
    }

    _PostAddActionsMeetingData:any;
    updateMeetingData() {
      this.loaderService.showLoader();
      this.myaction = this.addMeetingActionsData.map(action => ({
        id: action.Id,
        actionName: action.actionName,
        owner: action.owner,
        targetDate: action.targetDate,
        statusMappingId: action.statusMappingId,
        closureDate: action.closureDate,
        remarks: action.remarks,
        contractMeetingOccurrenceId: action.contractMeetingOccurrenceId,
        isDeleted: action.isDeleted,
        createdDate:action.createdDate ? action.createdDate : new Date(),
        createdBy:action.CreatedBy =this.privilegeService.getLoginUserId(),
       
      }));
     
      this.myaction.forEach((record:any) => {
        record.statusMappingId = record.statusMappingId.id;
        record.targetDate = this.datePipe.transform(record.targetDate, "MM/dd/yyyy");
      record.closureDate = this.datePipe.transform(record.closureDate, "MM/dd/yyyy");
        this._ContractMeetingActionsDetails.push(record);
      }, this); 
  
      this.contractMeetingDetails.Id = this.contractMeetingId;
      this.contractMeetingDetails.UpdatedBy =this.privilegeService.getLoginUserId(),
      this._contractMeetingOccurrences.OccurrenceDate = this.datePipe.transform(this._contractMeetingOccurrences.OccurrenceDate, "MM/dd/yyyy");
  
      if (this.attendeesValues !== "") {
        this._contractMeetingAttendees.Attendees = this.attendeesValues.map((attendee: { name: any; }) => attendee.name).join(',');
        this._contractMeetingAttendeesDetails.push(this._contractMeetingAttendees);
      }
  
      if (this.absenteesValues !== "") {
        this. _contractMeetingAbsentees.Absentees = this.absenteesValues.map((absentees: { name: any; }) => absentees.name).join(',');
        this. _contractMeetingAbsenteesDetails.push(this. _contractMeetingAbsentees);
      }

      this._contractmeetingActions=this.myaction;
      this.contractMeetingDetails.ContractMeetingOccurrences = this._contractMeetingOccurrenceDetails;
      
      this._contractMeetingOccurrences.ContractMeetingOccurrenceActions= this.myaction;
      this._contractMeetingOccurrenceDetails.push(this._contractMeetingOccurrences);
      this._ContractMeetingActionsDetails.push(this._contractmeetingActions)
      
      this.contractMeetingDetails.ContractMeetingOccurrences = this._contractMeetingOccurrenceDetails;
      this._contractMeetingOccurrences.ContractMeetingAbsentees= this._contractMeetingAbsenteesDetails;
      this._contractMeetingOccurrences.ContractMeetingAttendees = this._contractMeetingAttendeesDetails;
      this._contractMeetingOccurrences.ContractMeetingOccurrenceAttachments = this._contractMeetingOccurrenceAttachmentsDetails;
      this._contractMeetingOccurrences.ContractMeetingOccurrenceActions = this.myaction;
  
     
      this.governanceService.ContractUpdateMeetingData(this.contractMeetingDetails).subscribe(
        res => {
          this.loaderService.hideLoader();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Changes have been saved',
            detail: '',
          });
       
         const _dataResult = {
          contractId:this.contractDetailId,
          meetingId:this.meetingId,
        };
        this.viewMeetingButtonClick.emit(_dataResult);
        this.loaderService.hideLoader();
        this.compareModels();
        }
  
      );
      
    }
  _oldContractOccurences:any;
  _oldContractAttendees:any;
  _oldContractAbsentees:any;
  _oldContractAttachments:any;
  _oldContractActions:any;

    formOldContractMeeting(){   
    setTimeout(() => {
      this._oldContractMeeting=JSON.stringify(this.contractMeetingDetails);
    this._oldContractOccurences=JSON.stringify(this._contractMeetingOccurrences);
    this._oldContractAttendees=JSON.stringify(this._contractMeetingAttendees);
    this._oldContractAbsentees=JSON.stringify(this._contractMeetingAbsentees);
    this._oldContractAttachments=JSON.stringify(this._contractMeetingOccurrenceAttachments);
    this._oldContractActions=JSON.stringify(this._contractmeetingActions);

    }, 2000);
  }
  noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim().length === 0) {
      return { onlySpaces: true };
    }
    return null;
  }

downloadAttachment(data:any)
{
  var attachment = this._contractMeetingOccurrenceAttachmentsDetails.find(attachment => attachment.fileName === data.name);

  if (attachment !== null) {
    this.downloadFile(attachment?.fileName, attachment?.filePath);
  }


}


async downloadFile(fileName:any,filePath:any){

var fileDetail = {
  "fileName":fileName,
  "filePath":filePath
};
this._fileService.DownloadFile(fileDetail).subscribe(
  async (res:any)=>{
      var blobData = this._fileService.dataURItoBlob(res);
      if(blobData.size>0){
         var response = await this._fileService.downloadBase64File(blobData,fileDetail.fileName);
         this.loaderService.hideLoader();
      }
      else{
        this.loaderService.hideLoader();
        
      }
  } 
);
}


    changedContractMeetingBasicInfo:any;
    oldContractMeetingData:any;
    contractMeetingDetailBeforeUpdate:any
    compareModels() {
      this.contractMeetingDetailBeforeUpdate=JSON.parse(this.contractMeetingDetailBeforeUpdate)
      this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.OccurrenceDate=new DatePipe('en-US').transform(new Date( this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.OccurrenceDate), this._dateformart.AuditLogFormat);
      this.contractMeetingDetailBeforeUpdate.ContractId=this.contractMeetingDetails.ContractId;
      this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.ContractMeetingAbsentees=this.oldabsentees;
      this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.ContractMeetingAttendees=this.oldattendees;
      this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.ContractMeetingOccurrenceActions=this.oldactions;

      const actions = this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.ContractMeetingOccurrenceActions;

      actions.forEach((item: { closureDate: string | number | Date | null; targetDate: string | number | Date | null; }) => {
       if (item.closureDate) {
         item.closureDate = new DatePipe('en-US').transform(new Date(item.closureDate), this._dateformart.AuditLogFormat);
       }
       if (item.targetDate) {
         item.targetDate = new DatePipe('en-US').transform(new Date(item.targetDate), this._dateformart.AuditLogFormat);
       }
     });
     
      this.contractMeetingDetailBeforeUpdate.ContractMeetingOccurrences.ContractMeetingOccurrenceAttachments=this.uploadedFileNames;

      this.contractMeetingDetailBeforeUpdate=JSON.stringify(this.contractMeetingDetailBeforeUpdate)

      this.changedContractMeetingBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractMeetingDetailBeforeUpdate),this.contractMeetingDetails,this.auditEntities.ContractMeetings,this.auditIdentifierDetails.ContractMeetings,this.auditOperations.Update,this.auditModule.GovernanceService);
    }

    _allAttendees: string[] = [];   
    get _attendees() {
      if(this._finalAttendees!=undefined)
      return this._finalAttendees.filter((a: any) => !this.absenteesValues.includes(a));
    else
    return [];
    }
    get _absentees() {
      if(this._finalAttendees!=undefined)
      return this._finalAttendees.filter((a: any) => !this.attendeesValues.includes(a));
    else
    return [];
    
    }

    
contactHeader='';
contactSubmitted=false;
displayContractGroupDialog:boolean=false;
contractGroup:any;
currentField: any;
ownerAction:number=0;
openContractModel(field: string,index:number=0) {
  this.ownerAction=index;
  this.contactName='';
  this.contactHeader = 'Add Contact';
  this.currentField = field;
  this.contactFormGroup.reset();
  Object.keys(this.contactFormGroup.controls).forEach(key => {
    this.contactFormGroup?.get(key)?.setErrors(null);
  });
  this.contactSubmitted = false;
  this.contractGroup = { name: "" };
  this.displayContractGroupDialog = true;
}
  validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
    const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
    if (control.value && !alphaRegex.test(control.value)) {
      return { invalidNumberSpecialCharecters: true };
    }
    return null;
  }
newlyAddedContacts: any[] = [];
SaveMeetingContact() {
  this.contactSubmitted = true;

  if (this.contactFormGroup.valid) {
    const newContactName = this.contactFormGroup.value.contractName;
    const newCode = this._finalAttendees.length > 0 ? Math.max(...this._finalAttendees.map((contact: { code: any; }) => contact.code || 0)) + 1 : 1;

    const newContact: ContactMeetingContacts = {
      id: 0, 
      contactName: newContactName,
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      meetingId: this.contractMeetingDetails.Id ?? 0 
    };

    // Check for duplicates
    const isDuplicate= this.checkNameExists(newContactName)
    if (!isDuplicate) {
      switch (this.currentField) {
        case 'organizer':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          this.contractMeetingDetails.Organizer = newContact.contactName;
          const Attendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendee];
          this._attendees.push(Attendee);

          const Absentee = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentee);
          break;
        case 'attendees':
          const newAttendee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAttendee];
          this.attendeesValues = [...this.attendeesValues, newAttendee];
          this._attendees.push(newAttendee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'absentees':
          const newAbsentee = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, newAbsentee];
          this.absenteesValues = [...this.absenteesValues, newAbsentee];
          this._attendees.push(newAbsentee);
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          break;
        case 'owner':
          this.organizerOptions = [...this.organizerOptions, { label: newContact.contactName, value: newContact.contactName }];
          
          const Attendees = { name: newContact.contactName, code: newCode };
          this._finalAttendees = [...this._finalAttendees, Attendees];
          this._attendees.push(Attendees);
          const Absentees = { name: newContact.contactName, code: newCode };
          this._attendees.push(Absentees);
          this.addMeetingActionsData[this.ownerAction].Owner = newContact.contactName;
          break;
        default:
          break;
      }
      this.newlyAddedContacts.push(newContact);
      this.contractMeetingDetails.ContractMeetingContacts = [...this.contractMeetingDetails.ContractMeetingContacts ?? [], newContact];
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Contact Name already exists. Please enter a unique Name', detail: '' });
    }

    this.displayContractGroupDialog = false;
  }
}

closeContactDialog(){
  this.displayContractGroupDialog = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Contact Information has not been saved',
    detail: '',
});
  
}
checkNameExists(name: string): boolean {
  return this._finalAttendees.some((item: { name: string; }) => item.name === name);
}
}
