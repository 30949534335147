import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/Loader/loader.service';
import {Dateformat, breadcrumnsCodes } from 'src/app/shared/constants/global.constants';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { EngagementService } from '../../Services/engagement.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';

import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { environment } from 'src/environments/environment';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-engagements-list',
  templateUrl: './engagements-list.component.html',
  styleUrls: ['./engagements-list.component.css']
})
export class EngagementsListComponent {
  constructor(private privilegeService: PrivilegeService,
              private engagementService: EngagementService, private router: Router,
              private loaderService: LoaderService, private datePipe: DatePipe,
              private breadcrumbService: BreadcrumbService,
              private currencyService: CurrencyService,
              private _dashboaredService: DashboardService,
              private authService: AuthCommonService,
              private tabAndStateService: TabAndStateService,
      ) 
  {
  }
  engagementdata: any[] = [];
  _dateformart: any;
  breadcrumbs: MenuItem[] = [];
  _enumBreadcrumb: any;
  createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilege:boolean=false;
  showHideFiltersControls: boolean = false;
  isGridEnable: boolean = false;
  isTilesEnable : boolean = true;
  supplierIds :any;
  _engagements:any=[];
  ngOnInit() {
    var userData=this.privilegeService.getUserData();
    this.supplierIds=userData.suppliers;
    this._engagements=userData.engagements;
    this._enumBreadcrumb = breadcrumnsCodes;
    this.generateBreadcrumb();
    this._dateformart = Dateformat;
    this.loaderService.showLoader();
    this.GetAllEngagements();
    this.loaderService.hidefinalLoader();
    this.BindPrivileges();
    //this.showDashboard();
     this.tabAndStateService.setPreviousState('Profile', 0);
  }
  GetAllEngagements(){
    this.loaderService.showLoader();
    this.engagementService.GetEngagementsByGroupTypes(this._engagements)
    .subscribe(res => {
      this.loaderService.hideLoader();
      this.engagementdata = res.data;
    });
}


BindPrivileges(){
const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
this.createPrivilege = hasPermission(Privileges.CreateEngagement);
this.editPrivilege = hasPermission(Privileges.EditEngagement);
this.viewPrivilege = hasPermission(Privileges.ViewEngagement);
}


viewFunction(a: any) {
  this.router.navigate(['/ViewEngagement', a.id,1]);
}
  viewContract(a: any) {
    this.router.navigate(['/ViewEngagement', a.id,2]);
  }
  viewGovernance(a: any) {
    this.router.navigate(['/ViewEngagement', a.id,3]);
  }
  viewFinance(a: any){
    this.router.navigate(['/ViewEngagement', a.id,4]);
  }
  viewAssociate(a: any){
    this.router.navigate(['/ViewEngagement', a.id,5]);
  }


  viewContractGroup(a: any) {
    // this.router.navigate(['/ViewEngagement'], { queryParams: { id: parseInt(a.id), tabId: 1 } });
    this.router.navigate(['/ViewEngagement', a.id,1]);
  }

  onRowEditInit(item: any) {
    this.router.navigate(['/EditEngagement', item.id]);
  }

  ViewSupplier(item: any) {
    // item.id=item[0].id;
    this.router.navigate(['/ViewEngagement', item.id]);
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
    return formattedDate;
  }
  generateBreadcrumb() {
    this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.ENGL);
  }
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  getFormattedValue(value: number, currencyName: string): string {
    return this.currencyService.getFormattedValue(value, currencyName);
  }



  //dashboard code start from here


  reportingServiceURL="";

  showDashboard()
  {
    this.loaderService.showLoader();
  
    this.GetReportingServicesEmbedURL();
  }

engagementHomePageDashboardId:any;
contractGroupdashboardId:any;
  public async GetReportingServicesEmbedURL() {
    this.engagementHomePageDashboardId=environment?._engagementHomePageDashboardId;
    var model = {
      "dashboardID": this.engagementHomePageDashboardId,
      "token":this.authService.getCookieValue('LOGIN_TOKEN'),
      "privilege": [
        {
          "MenuName": "string",
          "isAllDataAvailable": true,
          "DataValues": []
        }
      ]
    };
  
    // Create an observable for the firstEmdbedURL
  this._dashboaredService.getEmbedUrlData(model).subscribe(
    async (res: any) => {

        this.reportingServiceURL=res.data;

        await this.Dashboard();
  });
  
    // Combine the observables using forkJoin
   
  }

  public async Dashboard() {
    var containerDiv = document.getElementById("dashboardContainer") || '';
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
   
    const frameOptions = {
      url: this.reportingServiceURL,
      container: containerDiv,
      //height: "20%",
      width: "100%",
      
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent:any, metadata:any) => {
        switch (changeEvent.eventName) {
          
            case 'FRAME_LOADED': {
            
                 this.loaderService.hideLoader();
              
                break;
            }
        }
    },
  }
    
   embeddingContext.embedDashboard(frameOptions);

  }

  
  //dashboard code end here
  showHideControls() {
    this.showHideFiltersControls = !this.showHideFiltersControls;
  }
  showGridControls() {
    this.isGridEnable = !this.isGridEnable;
    this.isTilesEnable = false;
    this.isGridEnable = true;

  }
  ShowTilesControls() {
    this.isTilesEnable = !this.isTilesEnable;
    this.isGridEnable = false;
    this.isTilesEnable = true;
  }
}


