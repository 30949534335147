import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { GetInvoiceFilter, InvoiceEmailPayload, InvoiceFilterDetails } from 'src/app/Finance/finances/Model/ContractInvoice';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, CodeList, CodeListStatusTypes, CurrencyType, CurruncyFormat, Dateformat, FileExtension, StatusCodes } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import {  } from 'src/environments/environment';


@Component({
  selector: 'app-cont-invoice-list',
  templateUrl: './cont-invoice-list.component.html',
  styleUrls: ['./cont-invoice-list.component.css']
})
export class ContInvoiceListComponent implements  OnInit {
  codelist: any[] = [] ;
  @Output() addButtonClick = new EventEmitter<void>();
  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() childData: EventEmitter<any> = new EventEmitter<any>();
 
 
  @Input() invoiceId:any;
  @Input() ContractId:any;
  @Input() contractStartDate:any;
  @Input() SupplierId: any;
  @Input() SponsorId: any;
  @Input() EngagementName: any;
  emailBodyData=new InvoiceEmailPayload();
  
  date: Date | undefined;
  codeListStatusTypes: any;
  codeListValues:any;
  statusCodes:any;
  statusData:any;
  selectednoofInvoiceValues: any;
  SelectedStatus:any;
  enterInvoiceName:any;
  selectedDate:any;
  startDate: any;
  endDate: any;
  maxDate: any
  minDate: any
  mailPopup:boolean=false;
  popupHeader:any;
  mailGroup:any;
  mailForm:FormGroup | undefined;
  mailSubmitted=false;
selectedInvoices: any;
contractInvoiceData:any;
filteredInvoiceData:[]=[];
StatusName: any[] = [];
invoiceData:any;
_dateformart :any;
invoiceTotalRecords:any;
status:any;
uniqueStatusNames: string[] = [];
isChecked: boolean = true;
showHiddenControls: boolean = false;
selectedInvoiceStatus:any='';
selectedStatus:any
public GetInvoiceWithSelectedFilters = new GetInvoiceFilter();
invoiceFilterDetailsModel=new InvoiceFilterDetails(); 
createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilege:boolean=false;
  EmailInvoiceReport:boolean=false;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  constructor(private privilegeService: PrivilegeService,private datePipe: DatePipe,
    private router:Router,private loaderService: LoaderService,private masterDataService: SponserService,
    private _financeInvoiceService:FinanceService,private route: ActivatedRoute,
    private currencyService: CurrencyService, private fb: FormBuilder, private messageService: MessageService,
     private confirmationService: ConfirmationService,
     private auditLogService: AuditLogService,private contractService: ContractService,
     private notificationService:NotificationService,private tabAndStateService: TabAndStateService){
   
  }
  invoiceValues: any[] = [
    { label: 'All', value: 0 },
    { label: '<5000', value: 4999 },
    { label: '>=5000', value: 5000 },
    { label: '>=10,000', value: 10000 },
    { label: '>=20,000', value: 20000 },
    { label: '>=30,000', value: 30000 },
    { label: '>=40,000', value: 40000 },
    { label: '>=50,000', value: 50000 },
    { label: '>=60,000', value: 60000 },
    { label: '>=70,000', value: 70000 },
    { label: '>=80,000', value: 80000 },
    { label: '>=90,000', value: 90000 },
    { label: '>=1,00,000', value: 100000 },
  ];
  currency:any;
  invoiceDueDateMinimumValue:any;
  invoiceDueDateMaximumValue:any;
  userId:any;
  ngOnInit() {
 
    this.financeData=this.tabAndStateService.getFYBalance();
    var userData=this.privilegeService.getUserData();
    this.userId=userData.id;
    this.auditIdentifierDetails=AuditIdentifier;
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this.startDate="";
    this.endDate="";
    this.popupHeader="";
    this.maxDate = new Date();
    this.minDate=new Date(this.contractStartDate);

    

    this.codeListValues=CodeList;
    this.codeListStatusTypes=CodeListStatusTypes
    this.GetMeetingStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.INVSTS);
    this.statusCodes=StatusCodes;
    
     this._dateformart=Dateformat;
     this.BindPrivileges();
     this.currency= this.route.snapshot.queryParamMap.get('currency');
     this.GetContractDetailsById(this.ContractId)
    
}
addContractInvoice()
{
      this.addButtonClick.emit();
}
BindPrivileges(){
  const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
   this.createPrivilege = hasPermission(Privileges.AddInvoice);
   this.EmailInvoiceReport=hasPermission(Privileges.EmailInvoiceReport);
   this.editPrivilege = hasPermission(Privileges.EditInvoice);
   this.viewPrivilege = hasPermission(Privileges.ViewInvoice);
   this.ExportPrivilege = hasPermission(Privileges.ExportListofInvoices);  
  }
GetMeetingStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings

  this.masterDataService.getCodeListData(enumId, type).subscribe(
    res => {
      if (res.data.length > 0) {
        this.statusData = res.data;
        this.populateStatusNames();    
      }
    }
  );
};


getStatusName(statusId: number): string {
  this.status = this.statusData.find((s: { id: number; }) => s.id === statusId);
  return this.status ? this.status.name : 'Unknown'; 
}

populateStatusNames() {
  const uniqueStatusNames1: any[] = [];
  this.statusData.forEach((invoice: any) => {
    const statusMappingId = invoice.id;
    if (uniqueStatusNames1.findIndex(item => item.value === statusMappingId) === -1) {
      uniqueStatusNames1.push({
        label: this.getStatusName(statusMappingId),
        value: statusMappingId,
      });
    }
  });
  this.GetFinanceInvoice(this.ContractId);
  setTimeout(() => {
    this.StatusName = uniqueStatusNames1;
  }, 2000);
}

invoiceListData:any;
GetFinanceInvoice(contractDetailId: number) { //Get Meeting Status's from code list for the Meetings

  this._financeInvoiceService.GetFinanceInvoiceId(contractDetailId).subscribe(
    res => {
      if (res.data.length > 0) {

        this.invoiceData = res.data;
        this.invoiceListData=res.data
        this.tabAndStateService.setInvoicesData(this.invoiceData);
        this.totalRecordCount=this.invoiceData.length;
        this.filteredRecordCount=this.invoiceData.length;
        this.invoiceData.forEach((invoiceData: any) => {
          invoiceData.statusname = this.getStatusName(invoiceData.statusMappingId);
      if(invoiceData.statusname == 'Paid' || invoiceData.statusname == 'Rejected'){
          invoiceData.invoiceDueDateClass="";
        }

      });
        this.invoiceTotalRecords=res.data;
      }
    }
  );
};

colourCoding=true;
DueDateChange(invoice: any) {
  if (invoice.paymentDate === null && invoice.dueDate) {
    const currentDate = new Date();
    const dueDate = new Date(invoice.dueDate);
    const timeDifference = dueDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  } else {
    return 0;
  }
}




onStatusChange(event:any){
  this.selectedInvoiceStatus = event.value;
  const selectedItemCount = this.selectedStatus.length;
  this.onStatusFilter();
}

onStatusFilter(){
  this.GetInvoiceWithSelectedFilters.Status = this.selectedStatus == undefined ? "" : this.selectedStatus;
  this.invoiceData=this.invoiceTotalRecords;
  if(this.selectedInvoiceStatus.length>0){
    var filteredStatus = this.selectedStatus.map((item: { label: any; value: any; }) => item.label).join(', ');
    filteredStatus =filteredStatus.toLowerCase();
    console.log(filteredStatus);

if (this.GetInvoiceWithSelectedFilters.Status != null && this.GetInvoiceWithSelectedFilters.Status != undefined && this.GetInvoiceWithSelectedFilters.Status.length > 0) {
  if (this.invoiceData.length == 0) {
    this.invoiceData = this.invoiceTotalRecords;
  }
  let statusFilterData: any[] = [];
  statusFilterData = [...statusFilterData, ...this.invoiceData.filter((a: { statusname: any; }) =>  filteredStatus.includes(a.statusname.toLowerCase()))];
  this.invoiceData = statusFilterData;
  
}
else{
  this.invoiceData=this.invoiceTotalRecords;
}
}
this.tabAndStateService.setInvoicesData(this.invoiceData);
}

// grid filter change event method call
onfilter(event: any) {
  this.invoiceData = this.invoiceTotalRecords;

  // invoice number filter
  if (this.invoiceFilterDetailsModel.InvoiceNumber != null && this.invoiceFilterDetailsModel.InvoiceNumber != undefined && this.invoiceFilterDetailsModel.InvoiceNumber != "") {
    this.invoiceData = this.invoiceData.filter((a: { invoiceNumber: string }) => 
      a.invoiceNumber.toLowerCase().indexOf(this.invoiceFilterDetailsModel.InvoiceNumber?.toLowerCase()) !== -1
  );
  }

  // Invoice Value filter
  if (this.invoiceFilterDetailsModel.InvoiceValue != null && this.invoiceFilterDetailsModel.InvoiceValue != undefined && this.invoiceFilterDetailsModel.InvoiceValue != "") {

    if (this.invoiceFilterDetailsModel.InvoiceValue == 4999) { 
      this.invoiceData =  this.invoiceData.filter((a: { invoiceValue: string | number | Date; }) => {
        return a.invoiceValue <= this.invoiceFilterDetailsModel.InvoiceValue;
      });
  }
  else{
    this.invoiceData =   this.invoiceData .filter((a: { invoiceValue: string | number | Date; }) => {
      return a.invoiceValue >= this.invoiceFilterDetailsModel.InvoiceValue;
    });
  }
 }

// Invoice Value filter

 if (this.invoiceFilterDetailsModel.Status != null && this.invoiceFilterDetailsModel.Status != undefined && this.invoiceFilterDetailsModel.Status.length > 0) {

  if(this.invoiceFilterDetailsModel.Status.length>0){
    var filteredStatus = this.invoiceFilterDetailsModel.Status.map((item: { label: any; value: any; }) => item.label).join(', ');
    filteredStatus =filteredStatus.toLowerCase();
   
    let statusFilterData: any[] = [];
    statusFilterData = [...statusFilterData, ...this.invoiceData.filter((a: { statusname: any; }) =>  filteredStatus.includes(a.statusname.toLowerCase()))];
    this.invoiceData = statusFilterData;
    }
}


// Invoice due date filter 
if (this.invoiceFilterDetailsModel.InvoiceDueDate != null && this.invoiceFilterDetailsModel.InvoiceDueDate!= undefined && this.invoiceFilterDetailsModel.InvoiceDueDate!='') {
  this.invoiceFilterDetailsModel.StartDate = this.invoiceFilterDetailsModel.InvoiceDueDate[0];
  this.invoiceFilterDetailsModel.EndDate = this.invoiceFilterDetailsModel.InvoiceDueDate[1];
}

if (this.invoiceFilterDetailsModel.InvoiceDueDate != null && this.invoiceFilterDetailsModel.StartDate!=null && this.invoiceFilterDetailsModel.StartDate!='' && this.invoiceFilterDetailsModel.StartDate != undefined &&
this.invoiceFilterDetailsModel.EndDate!=null && this.invoiceFilterDetailsModel.EndDate!='' && this.invoiceFilterDetailsModel.EndDate != undefined) {
 

  if ( this.invoiceFilterDetailsModel.StartDate != null) {
    this.invoiceFilterDetailsModel.StartDate = new Date(
      Date.UTC(this.invoiceFilterDetailsModel.StartDate.getFullYear(), this.invoiceFilterDetailsModel.StartDate.getMonth(), this.invoiceFilterDetailsModel.StartDate.getDate())
    );
  } 

  if (this.invoiceFilterDetailsModel.EndDate != null) {
    this.invoiceFilterDetailsModel.EndDate = new Date(
      Date.UTC(this.invoiceFilterDetailsModel.EndDate.getFullYear(), this.invoiceFilterDetailsModel.EndDate.getMonth(),this.invoiceFilterDetailsModel.EndDate.getDate())
    );
  } 

  this.invoiceData = this.invoiceData.filter((a: { dueDate: string | number | Date; }) => {
    const invoiceDueDate = new Date(a.dueDate);
    return invoiceDueDate >= this.invoiceFilterDetailsModel.StartDate && invoiceDueDate <= this.invoiceFilterDetailsModel.EndDate;
  });
 } 
this.filteredRecordCount=this.invoiceData.length;
 // filter method end
 this.tabAndStateService.setInvoicesData(this.invoiceData);
}

viewInvoice(rowData:any){
   const dataResult = {
    contractid:this.ContractId,
    invoiceId:rowData.id
  };
  this.viewButtonClick.emit(dataResult);
}
selectedContractInvoiceCount:any
onContractsInvoiceValueChange(event: any){

  this.selectedContractInvoiceCount = event.value;
  this.onContractInvoiceFilter();
}


onContractInvoiceFilter(){
  this.invoiceData = this.invoiceTotalRecords;

  if (this.selectedContractInvoiceCount != undefined && this.selectedContractInvoiceCount != null) {
    if (this.selectedContractInvoiceCount.value === 0) {
      this.invoiceData = this.invoiceTotalRecords;
  }
  else {
    if (this.selectedContractInvoiceCount.value == 4999) { 
      this.invoiceData =  this.invoiceData.filter((a: { invoiceValue: string | number | Date; }) => {
        return a.invoiceValue <= this.selectedContractInvoiceCount.value;
      });
  }
  else{
    this.invoiceData =   this.invoiceData .filter((a: { invoiceValue: string | number | Date; }) => {
      return a.invoiceValue >= this.selectedContractInvoiceCount.value;
    });
  }
}
}
this.tabAndStateService.setInvoicesData(this.invoiceData);
}

onkeywordfilter(){
  this.invoiceData = this.invoiceTotalRecords;
  if (this.invoiceTotalRecords) {
    if (this.enterInvoiceName != null && this.enterInvoiceName != undefined && this.enterInvoiceName != "") {
      this.invoiceData = this.invoiceData.filter((a: { invoiceNumber: string }) => 
        a.invoiceNumber.toLowerCase().indexOf(this.enterInvoiceName.toLowerCase()) !== -1
    );
    }
  }
  this.tabAndStateService.setInvoicesData(this.invoiceData);
}


ClearFilters() {
  this.enterInvoiceName = null;
  this.selectedContractInvoiceCount = null;
  this.selectednoofInvoiceValues=null;
  this.selectedDate = null;
  this.startDate = null;
  this.GetInvoiceWithSelectedFilters.StartDate = null;
  this.SelectedStatus = null;
  this.GetFinanceInvoice(this.ContractId);
  this.selectedStatus=null;
  this.selectedInvoices = []; //clear selected suppliers
  this.isChecked = true;

  this.invoiceFilterDetailsModel={
    InvoiceNumber: '',
    InvoiceValue: '',
    Status: [],
    StartDate:null,
    EndDate:null,
    InvoiceDueDate:null
  }
}


OnSelectDate() {

  if(this.startDate!=null && this.endDate!=null && this.startDate!='' && this.endDate!=''){
    this.selectedDate='';
    this.startDate='';
    this.endDate='';

    this.invoiceData = this.invoiceTotalRecords;
    if (this.enterInvoiceName != null && this.enterInvoiceName != undefined && this.enterInvoiceName != "") {
      this.onkeywordfilter();
     }
  }
  if (this.selectedDate != null && this.startDate!='') {
    this.startDate = this.selectedDate[0];
    this.endDate = this.selectedDate[1];

    if (this.startDate != null) {
      this.GetInvoiceWithSelectedFilters.StartDate = new Date(
        Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())
      );
    } else {
      this.GetInvoiceWithSelectedFilters.EndDate = null;
    }

    if (this.endDate != null) {
      this.GetInvoiceWithSelectedFilters.EndDate = new Date(
        Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate())
      );
    } else {
      this.GetInvoiceWithSelectedFilters.EndDate = null;
    }
  }
  if (this.startDate != null && this.endDate != null && this.startDate != '' && this.endDate != '') {
    this.ondateFilter();
  }
}

ondateFilter(){

  if (this.GetInvoiceWithSelectedFilters.StartDate != null && this.GetInvoiceWithSelectedFilters.StartDate != undefined && this.GetInvoiceWithSelectedFilters.EndDate != null && this.GetInvoiceWithSelectedFilters.EndDate != undefined) {
    this.invoiceTotalRecords = this.invoiceData.filter((a: { dueDate: string | number | Date; }) => {
      const engagementDate = new Date(a.dueDate);
      return engagementDate >= this.startDate && engagementDate <= this.endDate;
    });
    this.invoiceData = this.invoiceTotalRecords;
  }
  else{
    this.invoiceData = this.invoiceTotalRecords;
    if (this.enterInvoiceName != null && this.enterInvoiceName != undefined && this.enterInvoiceName != "") {
      this.onkeywordfilter();
     }
  }
}

CheckboxEnabled() {
  if (this.selectedInvoices.length > 0) {
    this.isChecked = false;
  }
  else {
    this.isChecked = true;
  }
}
ExportDateChange(event: any) {
  if (event) {
    const currentDate = new Date();
    const dueDate = new Date(event);
    const timeDifference = dueDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  } else {
    return 0;
  }
}
selectedRecordsToExpotToExcel: any[] = [];
async ExportInvoiceData() {
  const invoiceIds = this.selectedInvoices.map((invoice: { id:any; invoiceNumber: any; invoiceValue: any; invoiceRaisedDate: string | null; dueDate: string | null; daysToDueDate: any; status: any; paymentAmount: any; paymentDate: string | null; statusMappingId: any; draftInvoiceSentDate : string | null; draftInvoiceApprovedDate : string | null ; remarks : string | null; invoiceDescription : string | null })=> invoice.id);
 this._financeInvoiceService.GetContractFinanceInvoiceDetailsById(invoiceIds)
  const contractInvoicePromises = invoiceIds.map((invoiceId: number) => 
    this._financeInvoiceService.GetContractFinanceInvoiceDetailsById(invoiceId).toPromise()
  );
  try {
    const contractInvoiceDetails = await Promise.all(contractInvoicePromises);
    this.selectedRecordsToExpotToExcel = contractInvoiceDetails.map((res: any, index: number) => {
      const invoice = this.selectedInvoices[index]; 
      const data = res.data;
      let daysToDueDateValue = (this.getStatusName(invoice.statusMappingId) === 'Paid' || this.getStatusName(invoice.statusMappingId) === 'Rejected') ? 'NA' : this.ExportDateChange(data.dueDate);

      return {
        InvoiceNumber: invoice.invoiceNumber,
        InvoiceValue: invoice.invoiceValue,
        'Draft Invoice Sent Date': data.draftInvoiceSentDate ? this.convertDateFormat(data.draftInvoiceSentDate) : invoice.invoiceRaisedDate,
        'Draft Invoice Approved Date': data.draftInvoiceApprovedDate ? this.convertDateFormat(data.draftInvoiceApprovedDate) : invoice.draftInvoiceApprovedDate,
         'Invoice Upload Date': invoice.invoiceRaisedDate ? this.convertDateFormat(invoice.invoiceRaisedDate) : invoice.invoiceRaisedDate,
        DueDate: data.dueDate ? this.convertDateFormat(data.dueDate) : invoice.dueDate,
        DaysToDueDate: daysToDueDateValue,
        Status: this.getStatusName(data.statusMappingId),
        PaymentAmount: data.paymentAmount,
        PaymentDate: data.paymentDate ? this.convertDateFormat(data.paymentDate) : 'NA',
        Attachment: data.fileName,
        Remarks: data.remarks,
        'Invoice Description': data.invoiceDescription,
        'Added By': this.privilegeService.getUserName(data.createdBy),
        'Added Date and Time': data.createdDate ? this.convertDateTimeFormat(data.createdDate) : data.createdDate,
        'Modified By': this.privilegeService.getUserName(data.updatedBy),
        'Modified Date and Time': data.updatedDate ? this.convertDateTimeFormat(data.updatedDate) : data.updatedDate,
      };
    });

    this.exportExcel(this.selectedRecordsToExpotToExcel);
  } catch (error) {
    console.error('Error fetching contract invoice details', error);
  } finally {
    this.loaderService.hideLoader();
  }
  this.loaderService.hideLoader();
}


convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}
exportExcel(SelectedRecords: any) {
  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(SelectedRecords);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
   
    const fileName = `${this.ContractDetails.title} - InvoiceExport - ${this.convertDateFormat(Date())}.xlsx`;
    this.saveAsExcelFile(excelBuffer, fileName);
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = FileExtension.ExportExtention;
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName);
}
ShowAndHideFilterControls() {
  if (this.showHiddenControls) {
    this.showHiddenControls = false;
  }
  else {
    this.showHiddenControls = true;
  }
}
onInvoiceEdit(item: any) {
  this.editButtonClick.emit(item.id);
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}

emailReport() {
  this.popupHeader='Send Invoice Report Email' ;  
  this.mailPopup = true;
   this.selectedDateRange=null;
   this.emailBodyData.StartDate="";
   this.emailBodyData.EndDate="";
   this.emailBodyData.EngagementName="";
  this.emailBodyData.InvoicedAmount= 0;
  this.emailBodyData.BudgetBalanceFY=0;
  this.emailBodyData.CurrentFiscalYear="";
   this.oldEmailData = JSON.stringify(this.emailBodyData);
}

closeMailDialog(){
  this.mailPopup = false;
  this.messageService.add({
    key: 'tc',
    severity: 'info',
    summary: 'Notification not triggered',
    detail: '',
});
  
}
invoicedAmount:any=0;
sendNotification()
{
  this.confirmationService.confirm({
    message: 'This will trigger an email notification with the Invoice details for the selected date range for this contract. The notification will be sent to all stakeholders in this contract for whom "Finance" has been selected under "Send Notification". <br> <br> Are you sure you want to send this email? ',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      this.mailPopup = false;
      this.sendInvoiceReportEmail();
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Email Notification has been triggered',
        detail: '',
    });
    },
    reject: () => {
      this.mailPopup = false;
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: 'Notification not triggered',
        detail: '',
    });
    },
  });
}
selectedDateRange: any;
isSendEmailbtnEabled:boolean=true;
fiterInvoiceListData:any;
onEmailSelectDate() {
    this.startDate = this.selectedDateRange[0];
    this.endDate = this.selectedDateRange[1];
  if (this.startDate != null && this.endDate != null) {
    if (!isNaN(this.startDate) && !isNaN(this.endDate)) {
      this.isSendEmailbtnEabled=false;
      this.fiterInvoiceListData = this.invoiceListData.filter((a: { invoiceRaisedDate: string | number | Date; statusname: string}) => {
        const invoiceRaisedDate = new Date(a.invoiceRaisedDate);
        return (invoiceRaisedDate >= this.startDate && invoiceRaisedDate <= this.endDate) && a.statusname !== 'Rejected';
      });
    }
   this.invoicedAmount= this.fiterInvoiceListData.reduce((sum: any, invoice: { invoiceValue: any; }) => sum + invoice.invoiceValue, 0);
  }
}
oldEmailData:any;
newEmailData:any;
financeData:any;
async sendInvoiceReportEmail()
{
  this.financeData=this.tabAndStateService.getFYBalance();
  this.emailBodyData.StartDate=this.datePipe.transform(this.startDate, 'dd-MMM-yy') as string;
  this.emailBodyData.EndDate=this.datePipe.transform(this.endDate, 'dd-MMM-yy') as string;
  this.emailBodyData.EngagementName=this.EngagementName;
  this.emailBodyData.InvoicedAmount= this.getFormattedValue(this.invoicedAmount, this.currency);
  this.emailBodyData.BudgetBalanceFY=this.getFormattedValue(this.financeData.FYBugetBalacne, this.currency);
  this.emailBodyData.CurrentFiscalYear=this.financeData.FY;
  this.emailBodyData.createdBy= this.userId,
  await this.notificationService.InvoiceReportNotification(this.ContractId,this.emailBodyData,this.SupplierId,this.SponsorId);
  this.newEmailData =this.emailBodyData;
  
  this.auditLogForEmail();
}
auditIdentifierDetails:any;
auditEntities:any;
auditOperations:any;
auditModule:any;
auditLogForEmail() {
this.auditLogService.compareModels(JSON.parse(this.oldEmailData),this.newEmailData,this.auditEntities.MonthlyInvoiceEmail,this.auditIdentifierDetails.MonthlyInvoiceEmail,this.auditOperations.Create,this.auditModule.FinanceService);
}

ContractDetails:any;
GetContractDetailsById(id: any) {
  this.contractService.GetContractDtailsById(id).subscribe(res => {
    if (res.data != null) {
      this.ContractDetails = res.data;
    }
  })
}

convertDateTimeFormat(inputDateTime: string) {  // while export to excel display UTC format
  const date = new Date(inputDateTime);

  // Adjust for timezone offset
  const timezoneOffsetHours = 5; 
  const timezoneOffsetMinutes = 30;
  date.setUTCHours(date.getUTCHours() + timezoneOffsetHours);
  date.setUTCMinutes(date.getUTCMinutes() + timezoneOffsetMinutes);

  // Format date
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getUTCFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Format time in UTC
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes} UTC`;

  return `${formattedDate} ${formattedTime}`;
}

_contractFinanceInvoiceDetails :any;
GetContractFinanceInvoiceDetailsById() {
  this.loaderService.showLoader();
if (this.ContractId != undefined && this.ContractId != null && this.invoiceId != undefined && this.invoiceId != null) {
   this._financeInvoiceService.GetContractFinanceInvoiceDetailsById(this.invoiceId)
     .subscribe(res => {               
       this._contractFinanceInvoiceDetails = res.data;
     });
}
}

}
