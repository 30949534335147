import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GovernanceService } from '../../../Engagements/Services/governance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/Loader/loader.service';
import { Dateformat, FileExtension } from 'src/app/shared/constants/global.constants';
import { DatePipe } from '@angular/common';
import FileSaver from 'file-saver';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-con-meetings-list',
  templateUrl: './con-meetings-list.component.html',
  styleUrls: ['./con-meetings-list.component.css']
})
export class ConMeetingsListComponent {
  @Input() ContractId: any;
  @Input() supplierId: any;
  @Output() addMeetingButtonClick = new EventEmitter<void>();
  @Output() editMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewMeetingButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() Id: any;
 selectedContractMeeting:any;

_dateformart :any;
_enumBreadcrumb :any;
_contractMeetingListData: any[] = []
isChecked: boolean = true;
createPrivilege:boolean=false;
  editPrivilege:boolean=false;
  viewPrivilege:boolean=false;
  ExportPrivilage:boolean=false;
  totalRecordCount: number = 0;
  filteredRecordCount: number = -1;
  
constructor(private privilegeService: PrivilegeService,private governanceService: GovernanceService,
  private router:Router,private datePipe: DatePipe,  private _contractService : ContractService,
  private loaderService: LoaderService,private route: ActivatedRoute)
{

}


ngOnInit() {
  this._dateformart=Dateformat;
  this.getContractMeetingsByContractId();
  this.loaderService.hidefinalLoader();// hide after 4 seconds even get any error
  this.BindPrivileges();
  this.GetContractById(this.ContractId);
}
BindPrivileges(){
  const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
   this.createPrivilege = hasPermission(Privileges.AddContractMeeting);
this.editPrivilege = hasPermission(Privileges.EditContractMeeting);
this.viewPrivilege = hasPermission(Privileges.ViewContractMeeting);
this.ExportPrivilage= hasPermission(Privileges.ExportContractMeeting);

  }

addContractMeeting()
{
  this.addMeetingButtonClick.emit();
}

editContractMeeting(Id:any,recurring: boolean){
if (recurring === false){
const data = {
  isRecurring:false,
  contractId:this.ContractId,
  meetingId:Id,
  supplierId:this.supplierId
};
this.editMeetingButtonClick.emit(data);
}
else{

const data = {
  isRecurring:true,
  contractId:this.ContractId,
  meetingId:Id,
  supplierId:this.supplierId
};
this.editMeetingButtonClick.emit(data);
}
}


getContractMeetingsByContractId(){
this.loaderService.showLoader();
this.governanceService.getContractMeetingsByContractId(this.ContractId).subscribe(
  response=>{
   this._contractMeetingListData=response.data;
   this.totalRecordCount= this._contractMeetingListData.length;
    this.loaderService.hideLoader();
  }
);
}

viewContractMeeting(supMeeting:any)
{

var contractId= this.ContractId;
var meetingId=supMeeting.toString();
const data = {
  contractId:this.ContractId,
  meetingId:supMeeting.id
};
this.viewMeetingButtonClick.emit(data);
}

CheckboxEnabled() {
  if (this.selectedContractMeeting.length > 0) {
    this.isChecked = false;
  }
  else {
    this.isChecked = true;
  }
}

selectedRecordsToExpotToExcel: any[] = [];
async ExportContractMeetings() {
  const nonRecurringMeetings = [];
  const recurringMeetings = [];
  const openActionMeetings = [];

  for (const meeting of this.selectedContractMeeting) {
    const meetingDetails = await this.getContractMeetingsById(meeting.id);

    // Non-Recurring Meetings
    if (meetingDetails.recurring === 'No') {
      nonRecurringMeetings.push({
        'Meeting Title': meetingDetails.title,
        Date: meetingDetails.occurrenceDate != "" ? this.convertDateFormat(meetingDetails.occurrenceDate) : meetingDetails.occurrenceDate,
        Organizer: meetingDetails.organizer,
        'Agenda': meetingDetails.agenda,
        'Open Action Count': meeting.openActionsCount,
        Attendees: meetingDetails.attendees.join(', '),
        Absentees: meetingDetails.absentees.join(', '),
        'Meeting Notes': meetingDetails.meetingNotes,
        Decisions: meetingDetails.decisions,
        Attachment: meetingDetails.attachments,
        'Added By': this.privilegeService.getUserName(meetingDetails.createdBy),
        'Added Date': meetingDetails.createdDate != null ? this.convertDateFormat(meetingDetails.createdDate) : meetingDetails.createdDate,
        'Modified By': this.privilegeService.getUserName(meetingDetails.updatedBy),
        'Modified Date': meetingDetails.updatedDate != null ? this.convertDateFormat(meetingDetails.updatedDate) : meetingDetails.updatedDate,
      });
    }

    // Recurring Meetings
    if (meetingDetails.recurring === 'Yes') {
      recurringMeetings.push({
        'Meeting Title': meetingDetails.title,
        'Occurrence Date': meetingDetails.occurrenceDate != "" ? this.convertDateFormat(meetingDetails.occurrenceDate) : meetingDetails.occurrenceDate,
        Organizer: meetingDetails.organizer,
        'Agenda': meetingDetails.agenda,
        'Open Action Count': meeting.openActionsCount,
        Attendees: meetingDetails.attendees.join(', '),
        Absentees: meetingDetails.absentees.join(', '),
        'Meeting Notes': meetingDetails.meetingNotes,
        Decisions: meetingDetails.decisions,
        'Attachments': meetingDetails.attachments,
        'Added By': this.privilegeService.getUserName(meetingDetails.createdBy),
        'Added Date': meetingDetails.createdDate != null ? this.convertDateFormat(meetingDetails.createdDate) : meetingDetails.createdDate,
        'Modified By': this.privilegeService.getUserName(meetingDetails.updatedBy),
        'Modified Date': meetingDetails.updatedDate != null ? this.convertDateFormat(meetingDetails.updatedDate) : meetingDetails.updatedDate,
      });
    }

    // Meetings with Actions
    for (const action of meetingDetails.actions) {
      openActionMeetings.push({
        'Meeting Title': meetingDetails.title,
        'Meeting Date': meetingDetails.occurrenceDate  != "" ? this.convertDateFormat(meetingDetails.occurrenceDate ) : meetingDetails.occurrenceDate ,
        Organizer: meetingDetails.organizer,
        'Agenda': meetingDetails.agenda,
        Action: action.actionName,
        'Action Owner': action.owner,
        'Target Date': action.targetDate != null ? this.convertDateFormat(action.targetDate) : action.targetDate,
        'Action Status': action.actionStatus,
        'Closure Date': action.closureDate != null ? this.convertDateFormat(action.closureDate) : action.closureDate,
        'Remarks': action.remarks,
      });
    }
  }

  const workbook = xlsx.utils.book_new();

  if (nonRecurringMeetings.length > 0) {
    const worksheetNonRecurring = xlsx.utils.json_to_sheet(nonRecurringMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetNonRecurring, 'Non Recurring Meetings');
  }

  if (recurringMeetings.length > 0) {
    const worksheetRecurring = xlsx.utils.json_to_sheet(recurringMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetRecurring, 'Recurring Meetings');
  }

  if (openActionMeetings.length > 0) {
    const worksheetOpenActions = xlsx.utils.json_to_sheet(openActionMeetings);
    xlsx.utils.book_append_sheet(workbook, worksheetOpenActions, 'Actions');
  }

  const fileName = `${this.contractName} - MeetingsExport - ${this.convertDateFormat(Date())}.xlsx`;
  xlsx.writeFile(workbook, fileName);
}

exportExcelWorkbook(data: any[], sheetName: string) {
  const worksheet = xlsx.utils.json_to_sheet(data);
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);

  // Write the file
  const fileName = `${sheetName} - ${this.convertDateFormat(Date())}.xlsx`;
  xlsx.writeFile(workbook, fileName);
}



convertDateFormat(inputDateTime: string) {
  const date = new Date(inputDateTime);
  const formattedDate = this.datePipe.transform(date, this._dateformart.GridDateFormat);
  return formattedDate;
}


contractMeetingDetail:any;
async getContractMeetingsById(contractMeetingId: any) {
  const response: any = await this.governanceService.getContractMeetingsById(contractMeetingId).toPromise();
  const meetingDetails = response.data;
  const extractedDetails: any = {
    title: meetingDetails.title,
    organizer: meetingDetails.organizer,
    recurring: meetingDetails.recurring ? 'Yes' : 'No',
    agenda: meetingDetails.agenda,
    createdBy: meetingDetails.createdBy,
    createdDate: meetingDetails.createdDate,
    updatedBy: meetingDetails.updatedBy,
    updatedDate: meetingDetails.updatedDate,
    actions: [], // Store all actions
    attendees: [],
    absentees: [],
    meetingNotes: '',
    decisions: '',
    attachments: '',
    occurrenceDate: '', 
  };

  if (meetingDetails.contractMeetingOccurrences.length>0) {
    for (const occurrence of meetingDetails.contractMeetingOccurrences) {
      if (meetingDetails.recurring) {
        extractedDetails.occurrenceDate = occurrence.occurrenceDate || ''; 
      } else {
        if (!extractedDetails.occurrenceDate) {
          extractedDetails.occurrenceDate = occurrence.occurrenceDate || '';
        }
      }

      const occurrenceAttendees = occurrence.contractMeetingAttendees || [];
      const occurrenceAbsentees = occurrence.contractMeetingAbsentees || [];

      extractedDetails.attendees.push(...occurrenceAttendees.map((att: { attendees: any; }) => att.attendees).flat());
      extractedDetails.absentees.push(...occurrenceAbsentees.map((abs: { absentees: any; }) => abs.absentees).flat());

      extractedDetails.meetingNotes += occurrence.meetingNotes ? occurrence.meetingNotes  : '';
      extractedDetails.decisions += occurrence.decisions ? occurrence.decisions  : '';
      extractedDetails.attachments += occurrence.contractMeetingOccurrenceAttachments
        ? occurrence.contractMeetingOccurrenceAttachments.map((att: { fileName: any; }) => att.fileName).join(', ') : '';

      occurrence.contractMeetingOccurrenceActions.forEach((action: { actionName: any; owner: any; targetDate: any; actionStatus: any; closureDate: any; remarks: any; }) => {
        extractedDetails.actions.push({
          actionName: action.actionName,
          owner: action.owner,
          targetDate: action.targetDate,
          actionStatus: action.actionStatus,
          closureDate: action.closureDate,
          remarks: action.remarks,
        });
      });
    }
  }
  return extractedDetails;
}

contractName:any;
GetContractById(contractId:number){
  this._contractService.GetContractById(contractId).subscribe(
    res=>{
     this.contractName=res.data.title;
    }
  );
}

}
