<!DOCTYPE html>
<html lang="en">
<head>
   <title> Contract Invoice</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
    <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-4">
         <h3>{{"View Invoice"}}  <span> <em class="fr-subtitle_view"> </em> </span></h3>
      </div>
      <div class="fr-flex__col-8">
         <select class="form-control pull-right"  [(ngModel)]="selectedOption"  (change)="onOptionChange()">
            <option disabled selected > Actions </option>
            <option *ngIf="editPrivilege"> Edit </option>
            <option *ngIf="deletePrivilege"> Delete </option>

            </select>
            <div class="preview_section pull-right" *ngIf="currentIndex!==-1">
               <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
               <span class="numbers_list">{{getCurrentPositionText()}}</span>
               <button [class.next]="currentIndex !== listOfInvoices.length - 1" (click)="nextRecord()" [disabled]="currentIndex === listOfInvoices.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
               </div>
            <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
      </div>
    </div>
    <div class="scroll-start-here-sub-tabs">
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Invoice Number <em> * </em></label>
               <span> <em> {{_contractFinanceInvoiceDetails.invoiceNumber}} </em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Invoice Value​ <em> * </em></label>
               <span> <em> {{ getFormattedValue(_contractFinanceInvoiceDetails.invoiceValue, currency) }} </em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Draft Invoice Sent Date </label>
               <span> <em> {{_contractFinanceInvoiceDetails.draftInvoiceSentDate ? (_contractFinanceInvoiceDetails.draftInvoiceSentDate | date: _dateformart.GridDateFormat) : '--' }} </em></span>
            </div>
         </div>
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Draft Invoice Approved Date </label>
               <span> <em> {{_contractFinanceInvoiceDetails.draftInvoiceApprovedDate ? (_contractFinanceInvoiceDetails.draftInvoiceApprovedDate | date: _dateformart.GridDateFormat) : '--'}}  </em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Invoice Upload Date <em> * </em></label>
               <span> <em> {{_contractFinanceInvoiceDetails.invoiceRaisedDate | date: _dateformart.GridDateFormat}}  </em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Due Date <em> * </em></label>
               <span> <em> {{_contractFinanceInvoiceDetails.dueDate | date: _dateformart.GridDateFormat}} </em></span>
            </div>
         </div>
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Status  <em> * </em></label>
               <span> <em> {{_contractFinanceInvoiceDetails.statusMappingId}}</em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">      
      <div class="fr-form__view">
      <label> Attachment <i pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"></i>
      </label>
      <ng-container *ngIf="_contractFinanceInvoiceDetails.fileName; else noFile">
         <a class="attachment-active" (click)="downloadAttachemnt(_contractFinanceInvoiceDetails.fileName, _contractFinanceInvoiceDetails.filePath)">
            <span>
            <em>{{_contractFinanceInvoiceDetails.fileName}}</em>
            </span>
            <div *ngIf="_contractFinanceInvoiceDetails.fileName !== '' && _contractFinanceInvoiceDetails.fileName !== undefined">
            <i pInputText pTooltip="Download" tooltipPosition="top" (click)="downloadAttachemnt(_contractFinanceInvoiceDetails.fileName, _contractFinanceInvoiceDetails.filePath)" class="pi pi-download"></i>
            </div>
         </a>
      </ng-container>
      <ng-template #noFile>
         <span>
            <em>--</em>
         </span>
      </ng-template>
      </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Days to Due Date</label>
               <span > 
                  <em  [ngClass]="{'badge__error':_contractFinanceInvoiceDetails.invoiceDueDateClass === 'red', 'badge__warning': _contractFinanceInvoiceDetails.invoiceDueDateClass === 'orange', 'badge__success': _contractFinanceInvoiceDetails.invoiceDueDateClass === 'green'}">
                    {{ _contractFinanceInvoiceDetails.paymentDate === null && _contractFinanceInvoiceDetails.daysToDueDate !== null && 
                     !['Rejected', 'Paid'].includes(_contractFinanceInvoiceDetails.statusMappingId) ? 
                     (_contractFinanceInvoiceDetails.daysToDueDate === 0 ? '0' : DueDateChange(_contractFinanceInvoiceDetails)) : 
                     (_contractFinanceInvoiceDetails.daysToDueDate === null ? '--' : 'NA') }}
                     
               </em>
            </span>
            </div>
         </div>
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Payment Amount   </label>
               <span> <em> {{ _contractFinanceInvoiceDetails.paymentAmount!=null ? getFormattedValue(_contractFinanceInvoiceDetails.paymentAmount, currency) : '--' }} </em></span>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Payment Date </label>
               <span> <em> {{_contractFinanceInvoiceDetails.paymentDate ? (_contractFinanceInvoiceDetails.paymentDate | date: _dateformart.GridDateFormat) : '--'}}  </em></span>
            </div>
         </div>
        
    </div>
    <div class="fr-flex__container">
        <div class="fr-flex__col-4">
         <div class="fr-form__view">
            <label> Remarks  </label>
            <span class="full-width" *ngIf="remarks  == null || remarks == ''">--</span> <span class="full-width" [innerHTML]="remarks" *ngIf="remarks  != null"> {{remarks }} </span>
            </div>
        </div>
        <div class="fr-flex__col-4">
         <div class="fr-form__view">
          <label> Invoice Description</label>
          <span class="full-width" *ngIf="description  == null || description == ''">--</span> <span class="full-width" [innerHTML]="description" *ngIf="description  != null"> {{description }} </span>
          </div>
      </div>
        </div>
        <div class="fr-flex__container">
            
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added By </label>
                  <span> <em> {{createdBy? createdBy:"--" }}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Added Date & Time </label>
                  <span> <em> {{_contractFinanceInvoiceDetails.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified By </label>
                  <span> <em> {{updatedBy? updatedBy: "--"}}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified Date & Time </label>            
                  <span *ngIf="_contractFinanceInvoiceDetails?.updatedDate!=null"> <em> {{_contractFinanceInvoiceDetails?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}} </em></span>
                  <span *ngIf="_contractFinanceInvoiceDetails?.updatedDate==null"> <em> -- </em></span>
               </div>
             
            </div>
            </div>
         </div>
</div>
</body>
</html>