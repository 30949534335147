import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {  ForecastAttachments } from 'src/app/Finance/Models/contract-forecast';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';

import { AuditIdentifier, AuditLogEntities, AuditLogModule, AuditOperations, Dateformat } from 'src/app/shared/constants/global.constants';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-con-forecast-edit',
  templateUrl: './con-forecast-edit.component.html',
  styleUrls: ['./con-forecast-edit.component.css']
})
export class ConForecastEditComponent {
  

  @Output() cancelForecastButtonClick = new EventEmitter<void>();
  @Output() saveForecastButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() contractId: any;
  @Input() forecastId: any;
  forecastDetail:any;
  forecastDetailOld:any;
  forecastSubmitted:Boolean=false;
  isSaveBtnDisable = false;
  _dateformart :any;
  forecastForm: FormGroup;
  currentIndex: number = -1; 
  listOfForecasts:any;
  constructor(private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private confirmationService: ConfirmationService,private messageService: MessageService,private _fileService: FileUploadService,private datePipe: DatePipe,private formBuilder: FormBuilder,private financeService:FinanceService, private masterDataService: SponserService,private auditLogService: AuditLogService,private privilegeService: PrivilegeService, private tabAndStateService: TabAndStateService ){
  
    this.forecastForm = this.formBuilder.group({
      //_accrualAmount:['',[Validators.required, Validators.maxLength(15),this.validateNumericField]],
      _forecastedAmount: ['', [Validators.required,Validators.pattern(/^\d{1,13}\.\d{1,2}$|^\d{1,15}$/)]],
      _remarks:['', [Validators.maxLength(300)]],
      _forecastFile: [''], 
      _forecastFileName: '',
      _forecastAttachments: [''],
    });
  }


  attchement:any
  forecastAttachment:any;
  forecastAttachments: ForecastAttachments[]=[];
  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;
  updatedBy:any;
   ngOnInit() {
    this.BindData(this.forecastId)
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;
    this._dateformart=Dateformat;
    this.listOfForecasts=this.tabAndStateService.getForecasetData();
    this.currentIndex = this.listOfForecasts.findIndex((a: { forecastDate: any; }) => a.forecastDate ==this.forecastDetail.forecastDate);

}
BindData(data:any)
{
  this.forecastDetail =data;
  this.forecastAttachment= new ForecastAttachments();
  if(this.forecastDetail.id!=0)
    {
        if(this.forecastDetail?.updatedBy==null)
          this.forecastDetail.forecastedAmount=null;
          this.forecastDetail.contractDetailId=this.contractId;
         this.updatedBy=this.privilegeService.getUserName(this.forecastDetail.updatedBy);

      if(this.forecastDetail.financeContractForecastAttachments == null){
        this.forecastDetail.financeContractForecastAttachments.push(this.forecastAttachment)
      }
      else if(this.forecastDetail.financeContractForecastAttachments.length>0){
        this.forecastAttachment.fileName=this.forecastDetail.financeContractForecastAttachments[0].fileName;
        this.forecastAttachment.filePath=this.forecastDetail.financeContractForecastAttachments[0].filePath;
     }

    }
    else{
      this.forecastDetail.forecastedAmount=null;
      this.forecastDetail.remarks="";
      this.forecastDetail.contractDetailId=this.contractId;
      if(this.forecastDetail.financeContractForecastAttachments == null){
        this.forecastDetail.financeContractForecastAttachments=this.forecastAttachments;
        this.forecastDetail.financeContractForecastAttachments.push(this.forecastAttachment)
      }
     
    }
   
  
   this.auditIdentifierDetails=AuditIdentifier; 
   this.forecastDetailOld=JSON.stringify(this.forecastDetail);

   this.oldContractForecastData=JSON.stringify(this.forecastDetail);
  this.contractForecastDetailBeforeUpdate=JSON.stringify(this.forecastDetail);
}
    
validateNumericField(control: any) {
  const numericRegex = /^\d+(\.\d{1,2})?$/;
  if (control.value && !numericRegex.test(control.value)) {
    return { invalidNumber: true };
  }
  return null;
}

    
    
GetContractForecastDetailById() {
  if(this.forecastDetail.id>0){
    this.financeService.GetContractForecastDetailById(this.forecastDetail.id).subscribe(
      res=>{
        var forecastData=res.data;
      }
    );
  }
};



onCancelContractForecast()
{
if((this.forecastDetailOld==JSON.stringify(this.forecastDetail)))
{
this.cancelForecastButtonClick.emit();
}
else{
this.confirmationService.confirm({
header: 'Confirm',
message: 'Changes will not be saved, Do you want to Continue?',
accept: () => {
this.messageService.add({key: 'tc', severity: 'warn', summary: 'Changes not saved', detail: '',});
this.cancelForecastButtonClick.emit();
}
});
}
}

async saveContractForecast()
{
this.forecastSubmitted=true;
this.isSaveBtnDisable = true;
setTimeout(() => {
  this.isSaveBtnDisable = false;
}, 3000);
if(this.forecastForm.valid)
{
this.loaderService.showLoader();
if(this.deleteForecastFiles.length>0)
 await this.deleteForecastFile();
     var isUploadRequired=false;
       if(this.uploadedForecastFileData.length>0){
            
              this.forecastFormData.append("file",this.uploadedForecastFileData[0],this.forecastDetail.financeContractForecastAttachments[0].filePath);
              isUploadRequired=true;
           
       } 
        if(isUploadRequired) {
          this._fileService.UploadMultipleFile(this.forecastFormData).subscribe((isUploaded: any) => {
            if (isUploaded)
            {  
                // file uploaded successfully into the s3 bucket     
                this.updateForecastDetails();
            }
            else
            {
                this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Add forecast attachment failed to save', detail: '' })
                this.loaderService.hideLoader();
            }
          });
        }
        else
        {
              this.updateForecastDetails();
        }
}
else{
this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
}
}

updateForecastDetails(){

this.forecastDetail.forecastDate = this.datePipe.transform(this.forecastDetail.forecastDate, "MM/dd/yyyy");
this.forecastDetail.createdBy = this.privilegeService.getLoginUserId();
this.financeService.UpdateContractForecast(this.forecastDetail).subscribe(res => {
if(res.status)
{
  

  this.loaderService.hideLoader();
  this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
  this.compareModels();
  this.saveForecastButtonClick.emit();
       
}
else{
  this.loaderService.hideLoader();
}
});

}

onSaveContractAccrual()
{
this.saveForecastButtonClick.emit();
}


maxFileSize:any;
uploadedForecastFileData: any[] = [];
timeStamp:any;
forecastFormData = new FormData();


onForecastAttachmentChange(fileDetails:any) {
this.maxFileSize=this._fileService._fileSize; // 20 MB  (value Bytes  in binary)
// file upload on change event method
  if(fileDetails.length > 0) 
  {
     var currentFileSize=fileDetails[0].size;
     if(currentFileSize<=this.maxFileSize)
     {
       var uploadedFile = fileDetails[0];
       var uploadedExtension = uploadedFile.name.split('.').pop().trim().toLowerCase();
       if(this._fileService.allowedExtensions.includes(uploadedExtension))   
       {
                     this.forecastForm.patchValue({
                      _forecastFileName: uploadedFile.name
                      })
                 this.uploadedForecastFileData=[];
                 this.uploadedForecastFileData.push(uploadedFile);
                 this.timeStamp ="";
                 var utcDateTimeNow =  new Date().toISOString()
                 this.timeStamp = formatDate(utcDateTimeNow, 'ddMMyyyy-hhmmss', 'en-US');
                 // update File name / file relative path for UI and DB 

                this.forecastDetail.financeContractForecastAttachments[0].fileName=this.uploadedForecastFileData[0].name
                this.forecastDetail.financeContractForecastAttachments[0].filePath ="/Contracts/Finance/Forecast/" +this.timeStamp+"_"+ this.forecastDetail.financeContractForecastAttachments[0].fileName;
             
                this.forecastAttachment.fileName= this.forecastDetail.financeContractForecastAttachments[0].fileName;
                this.forecastAttachment.filePath= this.forecastDetail.financeContractForecastAttachments[0].filePath;
       }
       else{
         this.forecastForm.patchValue({
          _forecastFileName: '',
          _forecastFile: ''
       })
       this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Invalid file type only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
       }
 }
 else{
    this.forecastForm.patchValue({
      _forecastFileName: '',
      _forecastFile: ''
  })
  this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
}
}
}


deleteForecastFile() : any {
var isDeleted=false;
this._fileService.DeleteFile(this.deleteForecastFiles).subscribe(responseDelete=>{
   return isDeleted =responseDelete;
   } 
);
}



deleteForecastFiles : any[]=[]; 

prepareDeleteFileObject(){

if(this.forecastDetail.id!=0 && this.forecastDetail.financeContractForecastAttachments[0].id!=0){
   var fileDetail = {
     "fileName":this.forecastDetail.financeContractForecastAttachments[0].fileName,
     "filePath":this.forecastDetail.financeContractForecastAttachments[0].filePath
   };
   this.deleteForecastFiles.push(fileDetail);
  
  this.forecastAttachment.isPrevFileDelete=true;
}
   this.forecastDetail.financeContractForecastAttachments[0].fileName = '';
   this.forecastDetail.financeContractForecastAttachments[0].filePath = '';
   this.forecastAttachment.fileName= '';
   this.forecastAttachment.filePath= '';
   
   this.forecastForm.patchValue({
    _forecastFileName: '',
   })  
   this.uploadedForecastFileData=[];
}





fileDetail:any;
async downloadFile(fileName:any,filePath:any){

this.loaderService.showLoader();

if(this.uploadedForecastFileData.length>0){
this.prepareDownload(fileName, this.uploadedForecastFileData[0]);
}
else{
var fileDetail = {
"fileName":fileName,
"filePath":filePath
};
this._fileService.DownloadFile(fileDetail).subscribe(
async res=>{
    var blobData = this._fileService.dataURItoBlob(res);
    if(blobData.size>0){
       var response = await this._fileService.downloadBase64File(blobData,fileDetail.fileName);
       this.loaderService.hideLoader();
    }
    else{
      this.loaderService.hideLoader();
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Sorry, unable to download the file', detail: '' });
    }
} 
);
}
}

prepareDownload(filename:any, file:any) {



let fileContent= file;
const fileData=new Blob([fileContent], { type:"text/plain"});
const link = document.createElement('a');
link.href = URL.createObjectURL(fileData);
link.download=filename;
link.click();
link.remove();

this.loaderService.hideLoader();
}

changedContractForecastBasicInfo:any;
oldContractForecastData:any;
contractForecastDetailBeforeUpdate:any
compareModels() {
  this.changedContractForecastBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractForecastDetailBeforeUpdate),this.forecastDetail,this.auditEntities.ContractForecasts,this.auditIdentifierDetails.ContractForecasts,this.auditOperations.Update,this.auditModule.FinanceService);
}
navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfForecasts.length) {
    this.forecastDetail=this.listOfForecasts[index];
    this.BindData(this.forecastDetail);
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfForecasts.length}`;
}
pageChangeConfirmation(buttonType:number):void{
  if((this.forecastDetailOld==JSON.stringify(this.forecastDetail)))
  {
    buttonType==1?this.nextRecord():this.previousRecord();
  }
  else{
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
      });
       buttonType==1?this.nextRecord():this.previousRecord();
      },
      reject: () => {
    
      },
    });
  }
}
}
