<!DOCTYPE html>
<html lang="en">
<head>
  <title> Header & SideMenu </title>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta content="text/html; charset=UTF-16; X-Content-Type-Options=nosniff" http-equiv="Content-Type" />
</head>
<body>
<div class="layout-wrapper">
  <div class="sidebar-menu" [hidden]="!showSideMenu">
    <div class="menu-toggle">
      <div class="nav-toggleicon">
      <em class="icon-left-open"></em>
    </div>
    </div>
    <ul class="main-menu">
      <li class="hoverIconHide" [appRouterLinkActiveCustom]="['/home', '/CreateEngagement', '/ViewEngagement', '/EditEngagement']" activeClass="active">
        <a routerLink="/home">
          <em class="icon-label_outline"></em>
          <span>Home</span>
        </a>
      </li>
      <li [appRouterLinkActiveCustom]="['/contracts', '/createcontract', '/editcontract', '/viewcontract']" activeClass="active">
        <a routerLink="/contracts"> 
          <em class="icon-text-file2"></em> 
          <span>Contracts</span> 
        </a>
      </li>
      <li [appRouterLinkActiveCustom]="['/Dashboards', '/KPITrendDashboard', '/FinanceDashboard', '/TeamsDashboard']" activeClass="active">
        <a routerLink="/Dashboards">
          <em class="icon-pie-chart2"></em>
          <span>Dashboard</span>
        </a>
      </li>
      <li  class="has-submenu" [appRouterLinkActiveCustom]="['/codelist', '/sponsor', '/SuppliersListMasterData', '/createsponsor','/viewsponsor','/editsponsor', '/AddMasterSupplierData','/ViewMasterSupplier','/EditMasterSupplierData']" activeClass="active">
        <a  href="javascript:void(0)" class="menu_item" activeClass="ulactive"> <em class="pi pi-database"></em> <span class="first-child">Master Data<i class="icon-right-open"></i></span> </a>
        <ul class="main-submenu">
          <li [appRouterLinkActiveCustom]="['/codelist']" activeClass="active" routerLink="/codelist"><a> <em class="pi pi-circle-fill"> </em> <span>Code List</span> </a> </li>
          <li  [appRouterLinkActiveCustom]="['/sponsor', '/createsponsor','/viewsponsor','/editsponsor']" activeClass="active" routerLink="/sponsor"><a>  <em class="pi pi-circle-fill"> </em> <span>Sponsors</span> </a> </li>
          <li [appRouterLinkActiveCustom]="['/SuppliersListMasterData', '/AddMasterSupplierData','/ViewMasterSupplier','/EditMasterSupplierData']" activeClass="active" routerLink="/SuppliersListMasterData"><a>  <em class="pi pi-circle-fill"> </em> <span>Suppliers</span> </a> </li>
        </ul>
      </li>
      <li class="has-submenu" [appRouterLinkActiveCustom]="['/UserRoleMapping', '/ViewUserRolesMapping','/EditUserRolesMapping', '/Roles/RolesList', '/Roles/CreateRole', '/Roles/ViewRole', '/Roles/EditRole']" activeClass="active">
        <a href="javascript:void(0)" class="menu_item2"> <em class="icon-leadership"></em> <span>Admin<i class="icon-right-open"></i></span> </a>
        <ul class="main-submenu">
          <li routerLinkActive="active" *ngIf="userAdminAccessPrivilege"><a (click)="openACInNewWindow($event);"> <em class="pi pi-circle-fill"> </em> <span>User Admin</span> </a> </li>
          <li class="parent p-t-0">
            <a class="sub_menu" [appRouterLinkActiveCustom]="['/UserRoleMapping', '/ViewUserRolesMapping','/EditUserRolesMapping', '/Roles/RolesList', '/Roles/CreateRole', '/Roles/ViewRole', '/Roles/EditRole']" activeClass="active"> <em class="icon-user1"></em> <span>Authorization<i class="icon-right-open"></i></span> </a>
            <ul class="child child_menu">
              <li [appRouterLinkActiveCustom]="['/UserRoleMapping', '/ViewUserRolesMapping','/EditUserRolesMapping']" activeClass="active" routerLink="/UserRoleMapping"><a> <em class="pi pi-circle-fill"> </em> <span>User Role Mapping</span> </a> </li>
              <li [appRouterLinkActiveCustom]="['/Roles/RolesList', '/Roles/CreateRole','/Roles/ViewRole','/Roles/EditRole']" activeClass="active" routerLink="/Roles/RolesList"><a> <em class="pi pi-circle-fill"> </em> <span>Roles & Privileges</span> </a> </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- End of Navigation -->
  <div class="page-header" [hidden]="!showHeader">
    <div class="app-branding">
    <div class="app-logo p-l-20">
      <a href="javascript:void(0)" class="app-logo-btn">
        <span><img src="assets/images/Kontract Pro_Logo.png" alt=""> </span>
        <span class="app-secondary-logo-left"> Business Operational Portal </span>
      </a>
      <span class="info_header"> <i class="pi pi-info-circle"> </i> The information presented here is not the official source for contracting, financial, or compliance data, and should not be used for audits or inspections. For official data, please consult Takeda’s native systems such as Ariba, Linc, and Track wise.</span>
    </div>
     </div>
     <span class="app-secondary-logo-right"> <img class="takeda_img" src="assets/images/Takeda_Logo.png" alt="">   </span>
     <div class="user-dropdown pull-right">
        <span><img src="assets/images/profile_icon.svg" alt=""></span>
        <ul>
          <li (click)="Profile()"><i class="icon-user2"></i>Profile</li> 
           <li (click)="logOut()"><i class="icon-power-1"></i>Logout</li> 
        </ul>
  </div>
  </div> 
  <div [ngClass]="{'main-content': !hideClasses,}">
    <div [ngClass]="{'page-content': !hideClassess,}">
      <div *ngIf="loaderService.IsShowLoader" class="loader-overlay">
        <div class="card">
          <p-progressSpinner styleClass="custom-spinner" fill="var(--surface-ground)" strokeWidth="3"></p-progressSpinner>
      </div>
      </div>
      <p-toast position="top-center" key="tc"></p-toast>
      <router-outlet></router-outlet>
   </div>
   </div>
   <div class="copyrights"> Copyright © 2023 | Freyr KontractPro | All Rights Reserved </div>
</div>
</body>
</html>
