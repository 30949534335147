<!DOCTYPE html>
<html lang="en">
<head>
   <title> View Contract </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<div class="container page-title">
   <div class="flex-box-inside-pages position-fixed-heading"> 
    <div class="fr-flex__container">
        <div class="fr-flex__col-8">
           <h1>{{ContractDetails?.title}}-{{ContractDetails?.contractId}}</h1>
        </div>
      </div>
     </div>
     
     <p-tabView class="fr-tabview__header fixed-positon-tabs" #myTabView [(activeIndex)]="tabindex"  (onChange)="onTabSelect($event)" [activeIndex]="activeTabIndex">
        <p-tabPanel header="Details">
         <div class="card-pad"> 
         <div class="fr-flex__container sticky-header"> 
            <div class="fr-flex__col-6">
               <h3>View Contract</h3>
            </div>
         
            <div class="fr-flex__col-6" *ngIf="tabindex==0">
               <select  class="form-control selectpicker pull-right" [(ngModel)]="selectedOption" (change)="onOptionChange()">
                  <option disabled selected> Actions </option>
                  <option *ngIf="editPrivilege"> Edit </option>
                  </select>
                  <div class="preview_section pull-right">
                     </div>
      <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>

          
            </div>
           </div>
           <div class="scroll-start-here"> 
           <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Title <em> * </em></label>
                  <span [pTooltip]="ContractDetails?.title?.length > 30 ? ContractDetails?.title : ''" tooltipPosition="top"> <em> {{ContractDetails?.title}} </em></span>
               </div>
            </div>

            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Sponsor Contract ID <em> * </em></label>
                  <span [pTooltip]="ContractDetails?.contractId?.length > 30 ? ContractDetails?.contractId : ''" tooltipPosition="top"> <em> {{ContractDetails?.contractId}} </em></span>
               </div>
            </div>


            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Status <em> * </em></label>
                  <span> <em>  {{status}} </em></span>
               </div>
            </div>
            
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Engagement <em> * </em></label>
                  <span [pTooltip]="engagementName!.length > 30 ? engagementName : ''" tooltipPosition="top"> <em> {{engagementName}}</em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> MSA <em> * </em></label>
                  <span [pTooltip]="selectedMSA!.length > 30 ? selectedMSA : ''" tooltipPosition="top"> <em> {{selectedMSA}}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                   <label> Billing Type <em> * </em></label>                                 
                     <ul class="services_ul" *ngFor="let billing of BillingType?.split(','); let selectedBillingTypesIndexAfterSplit = index">
                         <li *ngIf="selectedBillingTypesIndexAfterSplit == 0">  {{billing ? billing : '--'}} </li>
                       </ul>
                       <span *ngIf="BillingType?.split(',').length > 1"><em>  <p-button (onClick)="ShowBillingTypesDialog()" [ngClass]="'blue-button'" [label]="'+'+(BillingType?.split(',').length - 1).toString()"></p-button> </em></span>
                       <p-dialog appendTo="body" header="Billing Type" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [closable]="true"  [(visible)]="visibleBillingsTypes">
                        <div class="dialog-ul" *ngFor="let billings of BillingType?.split(','); let selectedBillingTypesIndexAfterSplits = index">
                           <span *ngIf="selectedBillingTypesIndexAfterSplits > 0">                                       
                             <em> {{billings?.trim()}}</em>                    
                           </span>
                        </div>
                        </p-dialog>
                    
                </div>
             </div>
            
         </div>
        
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Start Date <em> * </em></label>
                  <span> <em> {{ContractDetails?.startDate | date: _dateformart.GridDateFormat }}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> End Date <em> * </em></label>
                  <span> <em> {{ContractDetails?.endDate | date: _dateformart.GridDateFormat}}  </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                   <label> Term </label>
                   <span> <em> {{ContractDetails?.term}}  </em></span>
                </div>
             </div>
            
         </div>
         <div class="fr-flex__container">
          
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                   <label> Function <em> * </em></label>
                   <span *ngIf="function !== undefined"></span>
                   <span [pTooltip]="function!.length > 30 ? function : ''" tooltipPosition="top"> <em> {{function}}  </em></span>
                </div>
             </div>
            
         </div>
         <div class="fr-flex__container">          
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Value <em> * </em> <i  pInputText pTooltip="This is the Base Contract/SOW Value" tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                  <span> <em>  {{ getFormattedValue(ContractDetails?.value, currencies) }} </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Total Value  <em> * </em> <i  pInputText pTooltip="This is the total value including any change orders." tooltipPosition="top" class="pi pi-info-circle"> </i> </label>
                  <span> <em> 
                     {{ getFormattedValue(ContractDetails?.totalValue, currencies) }} </em></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
                <div class="fr-form__view">
                   <label> Total Value/Year </label>
                   <span> <em> 
                     {{ getFormattedValue((ContractDetails.valuePerYear !== null ? ContractDetails.valuePerYear : 'NA'), currencies) }}</em></span>
                </div>
             </div>
         </div>
        
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> Currency <em> * </em></label>
                  <span> <em> {{currencies}}</em></span>
               </div>
            </div>
             <div class="fr-flex__col-4">
             <div class="fr-form__view">
                  <label> SOW Attachment </label>
                  <div *ngIf="ContractDetails.sowFileName != null && ContractDetails.sowFileName != ''; else noFile">
                     <a class="attachment-active" (click)="downloadAttachemnt(ContractDetails.sowFileName,ContractDetails.sowFilePath)"> 
                  <span [pTooltip]="ContractDetails?.sowFileName.length > 40 ? ContractDetails?.sowFileName : ''" tooltipPosition="top">
                  <em>  {{ContractDetails?.sowFileName ? ContractDetails?.sowFileName : '--' }} </em> 
                  </span>
                  <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
               </a> 
            </div>
            <ng-template #noFile>
               --
             </ng-template>
               </div>
            </div>

               </div>
            
          <div class="fr-flex__container">
             <div class="fr-flex__col-12">
                <div class="fr-form__view">
                   <label> Description </label>
                   <span class="full-width" *ngIf="description  == null || description == ''">--</span> <span class="full-width" [innerHTML]="description" *ngIf="description  != null"> {{description }} </span>
                </div>
             </div>
          </div>   
          <div class="fr-flex__container m-b-10"> 
            <div class="fr-flex__col-12"> 
            <div class="fr-flex">
               <h3>Purchase Order</h3>
           </div>
         </div>
         </div>      
          <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> PO Number <em> * </em></label>
                  <span> <span class="full-width">  {{ContractDetails?.poNumber}} </span></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> PO Value <em> * </em></label>
                  <span> <span class="full-width"> 
                     {{ getFormattedValue(ContractDetails?.poValue, currencies) }} </span></span>
               </div>
            </div> 
          
            
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> PO Start Date <em> * </em></label>
                  <span> <span class="full-width">  {{ContractDetails?.poStartDate |  date: _dateformart.GridDateFormat}} </span></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
               <div class="fr-form__view">
                  <label> PO End Date <em> * </em></label>
                  <span> <span class="full-width">  {{ContractDetails?.poEndDate |  date: _dateformart.GridDateFormat}} </span></span>
               </div>
            </div>
            <div class="fr-flex__col-4">
             <div class="fr-form__view">
                  <label> PO Attachment </label>
                 
                  <div *ngIf="ContractDetails?.poFileName != null && ContractDetails?.poFileName != ''; else noFile ">
                     <a class="attachment-active" (click)="downloadAttachemnt(ContractDetails.poFileName,ContractDetails.poFilePath)" >
                      <span [pTooltip]="ContractDetails?.poFileName.length > 45 ? ContractDetails?.poFileName : ''" tooltipPosition="top"> 
                        <em> {{ContractDetails?.poFileName ? ContractDetails?.poFileName : '--' }} </em> 
                     </span> 
                      <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
                     </a>

                  <span [pTooltip]="ContractDetails?.poFileName.length > 45 ? ContractDetails?.poFileName : ''" tooltipPosition="top" class="display-inline-block">  
                      </span> 
                     </div>
                     <ng-template #noFile>
                        --
                      </ng-template>

               </div>
            </div>
         </div> 
         <div class="fr-flex__container"> 
            <div class="fr-flex__col-12">
               <div class="fr-form__view">
                  <label> PO Remarks </label>
                  <span class="full-width" *ngIf="poRemarks  == null || poRemarks == ''">--</span> <span class="full-width" [innerHTML]="poRemarks" *ngIf="poRemarks  != null"> {{poRemarks }} </span>
               </div>
            </div>
         </div> 
      </div>


         <div class="fr-flex__container m-b-10"> 
            <div class="fr-flex__col-12"> 
            <div class="fr-flex">
               <h3>Contract Stakeholders</h3>
           </div>
         </div>
         </div>
         <div class="fr-flex__container">
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Supplier Contract Owner <em> * </em></label>
               <span id="contractviewSupplierContractOwnerTitle" [pTooltip]="selectedSupplierContact!.length > 30 ? selectedSupplierContact : ''" tooltipPosition="top"> <em>  {{selectedSupplierContact}} </em></span>
            </div>
            <div class="" *ngFor="let objSupplierContact of supplierContactList; let i = index">
               <div class="fr-card__custom">
                  <p-card>
                     <ng-template pTemplate="content">
                        <p class="fr-card__contact--title">  {{ objSupplierContact.contactName }} </p>
                        <div class="fr-card__view">
                           <label> Designation  </label>  
                           <span [pTooltip]="objSupplierContact.designation!.length > 29 ? objSupplierContact.designation : ''" tooltipPosition="top" class="valueSelector"> <em class="designation_width"> {{ objSupplierContact.designation? objSupplierContact.designation : '--'  }}  </em> </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Country Code  </label>  
                           <span class="valueSelector"> {{ objSupplierContact.countryCode? objSupplierContact.countryCode : '--'  }} </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Phone Number </label> 
                           <span class="valueSelector"> {{ objSupplierContact.phoneNumber? objSupplierContact.phoneNumber : '--'  }} </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Email <em> * </em> </label> 
                           <span [pTooltip]="objSupplierContact.email!.length > 29 ? objSupplierContact.email : ''" tooltipPosition="top" class="valueSelector"> <em class="email_width"> {{ objSupplierContact.email}} </em>  </span>
                        </div>
         
                     </ng-template>
                   
                     <ng-template pTemplate="footer">
                     </ng-template>
                  </p-card>
                  
               </div>
            </div>
         </div>
         <div class="fr-flex__col-4">
            <div class="fr-form__view">
               <label> Sponsor Contract Owner<em> * </em></label>
               <span id="contractviewSponsorContractOwnerTitle" [pTooltip]="sponserContactName!.length > 30 ? sponserContactName : ''" tooltipPosition="top"> <em> {{sponserContactName}}  </em></span>
            </div>
            <div class="" *ngFor="let objContact of sponserContactList; let i = index">
               <div class="fr-card__custom">
                  <p-card>
                     <ng-template pTemplate="content">
                        <p class="fr-card__contact--title">  {{ objContact.name }} </p>
                        <div class="fr-card__view">
                           <label> Designation  </label>  
                           <span [pTooltip]="objContact.designation!.length > 29 ? objContact.designation : ''" tooltipPosition="top" class="valueSelector"> <em class="designation_width"> {{ objContact.designation? objContact.designation : '--'  }} </em>  </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Country Code  </label>  
                           <span class="valueSelector"> {{ objContact.countryCode? objContact.countryCode : '--'  }} </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Phone Number </label> 
                           <span class="valueSelector"> {{ objContact.phoneNumber? objContact.phoneNumber : '--'  }} </span>
                        </div>
                        <div class="fr-card__view">
                           <label> Email <em> * </em> </label> 
                           <span [pTooltip]="objContact.email!.length > 29 ? objContact.email : ''" tooltipPosition="top" class="valueSelector"> <em class="email_width"> {{ objContact.email}} </em>  </span>
                        </div>
         
                     </ng-template>
                   
                     <ng-template pTemplate="footer">
                     </ng-template>
                  </p-card>
                  
               </div>
            </div>
         </div>
         </div>

         
<!-- Stakeholder Sponsor Contact Grid Start -->
<div class=""> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h3>Stakeholders (Sponsor)  </h3>
      </div>
      <div class="fr-flex__col-4">
      </div>
   </div>
   
   <div class="fr-flex__col-12 m-b-30"> 
      <p-table id="viewContractStakeholdersSponsorContactListTableName" class="primeng-table-custom engViewStakeholders_contact" styleClass="p-datatable-striped" [scrollable]="true" [value]="stakeholdercontactData" editMode="row">
          <ng-template pTemplate="header">
              <tr>   
                                   
               <th id="conViewStakeholderSponsorName">Name </th>
               <th id="conViewStakeholderSponsorDesignation">Designation</th>
               <th id="conViewStakeholderSponsoEmail">Email</th>
               <th id="conViewStakeholderSponsoCountryCode">Country Code </th>
               <th id="conViewStakeholderSponsoPhoneNumber">Phone Number</th>
               <th id="conViewStakeholderSponsoSendNotification">Send Notifications </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-stakeholdercontactData let-sponsorIndex="rowIndex"> 
          
            <tr>    
               <td>
                  {{ stakeholderSponsorContacts[sponsorIndex]?.name || '--' }} 
              </td>
               <td [pTooltip]="stakeholderSponsorContacts[sponsorIndex]?.designation.length > 20 ? stakeholderSponsorContacts[sponsorIndex]?.designation : ''" tooltipPosition="top">
                  {{ stakeholderSponsorContacts[sponsorIndex]?.designation || '--' }} 
              </td>
              <td [pTooltip]="stakeholderSponsorContacts[sponsorIndex]?.email.length > 20 ? stakeholderSponsorContacts[sponsorIndex]?.email : ''" tooltipPosition="top">
                  {{ stakeholderSponsorContacts[sponsorIndex]?.email || '--' }} 
              </td>
              <td [pTooltip]="stakeholderSponsorContacts[sponsorIndex]?.countryCode.length > 15 ? stakeholderSponsorContacts[sponsorIndex]?.countryCode : ''" tooltipPosition="top">
                  {{ stakeholderSponsorContacts[sponsorIndex]?.countryCode || '--' }} 
              </td>
              <td>
                 {{ stakeholderSponsorContacts[sponsorIndex]?.phoneNumber || '--' }} 
              </td>
              <td>
               {{ getCommaSeparatedSponsorNames(sponsorStakeholderDetails[sponsorIndex].sendNotificationOptions)? getCommaSeparatedSponsorNames(sponsorStakeholderDetails[sponsorIndex].sendNotificationOptions) : '--' }}
           </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
         <tr>
         <td colspan="7" class="text-center"> No Records</td>
         </tr>
         </ng-template>
      </p-table>
      
   
   </div>
   
  
   <!-- Stakeholder Sponsor Contact Grid End -->
   
   <!-- Stakeholder Supplier Contact Grid Start -->
   <div class="fr-flex__container">
      <div class="fr-flex__col-8">
          <h3>Stakeholders (Supplier)  </h3>
      </div>
      <div class="fr-flex__col-4">
      </div>
   </div>
   
   <div class="fr-flex__col-12 m-b-20"> 
      <p-table id="ViewContractStakeholdersSupplierContactListTableName" class="primeng-table-custom engViewStakeholders_contact" styleClass="p-datatable-striped" [scrollable]="true" [value]="stakeholdersuppliercontactData" editMode="row">
          <ng-template pTemplate="header">
              <tr>                         
               <th id="conViewStakeholderSupplierName">Name </th>
               <th id="conViewStakeholderSupplierDesignation">Designation</th>
               <th id="conViewStakeholderSupplierEmail">Email</th>
               <th id="conViewStakeholderSupplierCountryCode">Country Code </th>
               <th id="conViewStakeholderSupplierPhoneNumber">Phone Number</th>
               <th id="conViewStakeholderSupplierSendNotofications">Send Notifications </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-stakeholdersuppliercontactData let-supplierIndex="rowIndex"> 
          
            <tr>                    
               <td >
                  {{ stakeholderSupplierContacts[supplierIndex]?.contactName || '--' }} 
              </td>
               <td [pTooltip]="stakeholderSupplierContacts[supplierIndex]?.designation.length > 30 ? stakeholderSupplierContacts[supplierIndex]?.designation : ''" tooltipPosition="top">
                  {{ stakeholderSupplierContacts[supplierIndex]?.designation || '--' }} 
              </td>
              <td [pTooltip]="stakeholderSupplierContacts[supplierIndex]?.email.length > 30 ? stakeholderSupplierContacts[supplierIndex]?.email : ''" tooltipPosition="top">
                  {{ stakeholderSupplierContacts[supplierIndex]?.email || '--' }} 
              </td>
              <td>
                  {{ stakeholderSupplierContacts[supplierIndex]?.countryCode || '--' }} 
              </td>
              <td>
                  {{ stakeholderSupplierContacts[supplierIndex]?.phoneNumber || '--' }} 
              </td>
               
            <td>
              {{ getCommaSeparatedSupplierNames(supplierStakeholderDetails[supplierIndex].sendNotificationOptions)? getCommaSeparatedSupplierNames(supplierStakeholderDetails[supplierIndex].sendNotificationOptions) : '--' }}
            </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
         <tr>
         <td colspan="7" class="text-center"> No Records</td>
         </tr>
         </ng-template>
      </p-table>
      
   </div>
  

  
</div>
   <!-- Stakeholder Sponsor Contact Grid End -->


         <div class="fr-flex__container">
            <div class="fr-flex__col-8">
             <div class="sub-page-title"> 
               <h1>Change Orders</h1>
            </div>
            </div>
         </div>
         <div class="fr-flex__container">
          <div class="fr-flex__col-12"> 
            <div class="card-norecord"> 
          <div class="card flex justify-content-center"  *ngIf="changeOrders.length==0">
             <p-card header="">
               <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
                <p class="m-0">
                  No Records
                </p>
             </p-card>
          </div>
         </div>
       </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4" *ngFor="let objChangeOrders of changeOrders;">
               <div id="ViewContractChangeOrderCard" class="fr-card__custom">
                  <p-card>
                     <ng-template pTemplate="content" >
                        <div class="fr-card__view">
                           <span class="valueSelector"> <a [pTooltip]="objChangeOrders.title!.length > 40 ? objChangeOrders.title! : ''" tooltipPosition="top">  {{objChangeOrders.title}} </a></span>
                           <span class="valueSelector success"> {{objChangeOrders.statusMappingId}} </span>
    
                        </div>
                        
                        <div class="fr-card__view">
                         <span class="valueSelector"> {{objChangeOrders.startDate | date: _dateformart.GridDateFormat}} - {{objChangeOrders.endDate | date: _dateformart.GridDateFormat}}   </span>
                         <span class="valueSelector"> <span class="right_align" 
                           [pTooltip]="objChangeOrders?.value!.length > 10 ? objChangeOrders?.value! : ''" 
                           tooltipPosition="top">  {{(objChangeOrders?.value) |  currency:getCurrencyType(currencies) }} </span></span>
                      </div>
                      <div class="fr-card__view">
                        <span class="valueSelector viewcontract-mb__0"> 
                           <ng-container *ngIf="objChangeOrders.fileName && objChangeOrders.fileName != ''; else noAttachment">
                              <a class="form-list-active change-order-attachment custom-m-b-zero" (click)="downloadAttachemnt(objChangeOrders.fileName,objChangeOrders.filePath)">
                                  <span [pTooltip]="objChangeOrders.fileName.length > 20 ? objChangeOrders.fileName : ''" tooltipPosition="top" routerLinkActive="active">
                                      {{ objChangeOrders.fileName }} 
                                  </span> 
                                  <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i> 
                              </a>
                          </ng-container>
                          <ng-template #noAttachment>
                           <a class="form-list-active noattachment"> --  </a> 
                       </ng-template>
                        </span>
                         <span class="valueSelector"> {{objChangeOrders.currencyId}} </span>  
                        </div>
                      <div class="fr-card__view">
                        <span class="valueSelector" [innerHTML]="changeorderDescription">  Description  <i  pInputText pTooltip={{changeorderDescription}} tooltipPosition="top" class="pi pi-info-circle"> </i> </span>
                            
                     </div>
                     <div class="fr-card__view">
                        <span class="valueSelector"> Description <i pInputText
                              pTooltip={{objChangeOrders.description}} tooltipPosition="top"
                              class="pi pi-info-circle"> </i> </span>
                     </div>
                     </ng-template>
                  </p-card>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-8">
             <div class="sub-page-title"> 
               <h1>Addendum</h1>
            </div>
            </div>
         </div>
         <div class="fr-flex__container">
          <div class="fr-flex__col-12"> 
            <div class="card-norecord">  
          <div class="card flex justify-content-center"  *ngIf="contractAddendumDetails.length==0">
             <p-card header="">
               <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
                <p class="m-0">
                  No Records
                </p>
             </p-card>
          </div>
          </div>
       </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-4" *ngFor="let objAddendumDetails of contractAddendumDetails;">
               <div id="viewContractAddendumCard" class="fr-card__custom">
                  <p-card>
                     <ng-template pTemplate="content">
                        <div class="fr-card__view">
                           <span class="valueSelector"> 
                              <a [pTooltip]="objAddendumDetails.title!.length > 15 ? objAddendumDetails.title! : ''" tooltipPosition="top"> {{objAddendumDetails.title}} 

                              </a> 
                           </span>
                           <span class="valueSelector success"> {{objAddendumDetails.statusMappingId}} </span>
                        </div>
                        
                        <div class="fr-card__view">
                           <span class="valueSelector"> {{objAddendumDetails.startDate | date: _dateformart.GridDateFormat}} - {{objAddendumDetails.endDate | date: _dateformart.GridDateFormat}}  </span>
                        </div>
                      <div class="fr-card__view">
                        <span class="valueSelector-full-width viewcontract-mb__0">
                           <ng-container *ngIf="objAddendumDetails.fileName && objAddendumDetails.fileName != ''; else addendumnoAttachment">
                              <a class="form-list-active custom-m-b-zero" (click)="downloadAttachemnt(objAddendumDetails.fileName,objAddendumDetails.filePath)"> 
                                  <span [pTooltip]="objAddendumDetails.fileName.length > 30 ? objAddendumDetails.fileName : ''" tooltipPosition="top" routerLinkActive="active">
                                      {{ objAddendumDetails.fileName | truncate:35 }} 
                                  </span>
                                  <i pInputText pTooltip="Download" tooltipPosition="top" class="pi pi-download"></i>
                              </a>
                          </ng-container>
                          <ng-template #addendumnoAttachment>
                           <a class="form-list-active addendnoattachment"> --  </a> 
                       </ng-template>
                  </span>
                      </div>
                      <div class="fr-card__view">
                        <span class="valueSelector" [innerHTML]="addendumDescription"> Description  <i  pInputText pTooltip={{addendumDescription}} tooltipPosition="top" class="pi pi-info-circle"> </i> </span>
                        </div>
                        <div class="fr-card__view">
                           <span class="valueSelector"> Description <i pInputText pTooltip={{objAddendumDetails.description}} tooltipPosition="top" class="pi pi-info-circle"> </i> </span>
                        </div>
                     </ng-template>
                  </p-card>
               </div>
            </div>
         </div>
         <div class="fr-flex__container">
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Created By <em> * </em></label>
                  <span> <em>  {{ContractCreatedUserName}} </em></span>
               </div>
            </div>
              <div class="fr-flex__col-3">
                 <div class="fr-form__view"> 
                    <label> Created Date & Time <em> * </em></label>
                    <span> <em> {{ContractDetails?.createdDate | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}}</em></span>
                 </div>
              </div>   
              <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified By <em> * </em></label>
                  <span> <em> {{ContractUpdatedUserName}}  </em></span>
                  <span *ngIf="ContractDetails?.updatedBy==null"> <em> -- </em></span>
               </div>
            </div>
            <div class="fr-flex__col-3">
               <div class="fr-form__view">
                  <label> Modified Date & Time <em> * </em></label>
                  <span *ngIf="ContractDetails?.updatedBy != null"> <em> {{ContractDetails?.updatedDate | date: _dateformart.DateTimeWithZone}}  {{_dateformart.DisplayUTC}}</em></span>
                  <span *ngIf="ContractDetails?.updatedBy==null"> <em> --</em></span>
               </div>
            </div> 
           </div>
      </div>
        </p-tabPanel>   
        <p-tabPanel header="Governance">
         <p-tabView class="fr-tabview__subheader" #mySubTabView (onChange)="onSubTabSelect($event)" [activeIndex]="activeSubTabIndex">
            <p-tabPanel header="KPIs"> 
                <app-con-kpi-list *ngIf="_kpiChildComponents.conKpiList==_currentKpiChildComponent"  [contractDetailId]="ContractId" (addKpiButtonClick)="handleAddButtonClick(1)" (editKpiButtonClick)="handleEditButtonClick($event,1)" (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-con-kpi-list>
                <app-con-create-kpi *ngIf="_kpiChildComponents.conKpiAdd==_currentKpiChildComponent" [contractDetailId]="ContractId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)" (saveKpiButtonClick)="handleSaveAddButtonClick(1)" (viewKpiButtonClick)="handleViewButtonClick($event,1)"> </app-con-create-kpi>
                <app-con-edit-kpi *ngIf="_kpiChildComponents.conKpiEdit==_currentKpiChildComponent"  [contractDetailId]="ContractId"  [SupplierId]="SupplierId"    [SponsorId]="SponsorId"  [EngagementName]="EngagementName" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)" (viewKpiButtonClick)="handleViewButtonClick($event,1)" [kpiId]="kpiId"> </app-con-edit-kpi>
                <app-con-view-kpi *ngIf="_kpiChildComponents.conKpiView==_currentKpiChildComponent"  [contractDetailId]="ContractId" [kpiId]="kpiId" (cancelKpitButtonClick)="handleCancelAddButtonClick(1)" (editKpiButtonClick)="handleEditButtonClick($event,1)"> </app-con-view-kpi>
               </p-tabPanel>
               <p-tabPanel header="Meetings">
               <app-con-meetings-list *ngIf="_meetingChildComponents.conMeetingList==_currentMeetingChildComponent"  [ContractId]="ContractId" (addMeetingButtonClick)="handleAddButtonClick(2)"(editMeetingButtonClick)="handleEditButtonClick($event,2)" (viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-con-meetings-list>
               <app-con-create-meeting *ngIf="_meetingChildComponents.conMeetingAdd==_currentMeetingChildComponent" [supplierName]="supplierName" [ContractId]="ContractId" [EngagementId]="engagementId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)" (saveMeetingButtonClick)="handleSaveAddButtonClick(2)"(viewMeetingButtonClick)="handleViewButtonClick($event,2)"> </app-con-create-meeting>
               <app-con-edit-meeting *ngIf="_meetingChildComponents.conMeetingEdit==_currentMeetingChildComponent" [supplierName]="supplierName" [ContractId]="ContractId"  [EngagementId]="engagementId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)"(viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId"> </app-con-edit-meeting>
               <app-con-edit-meeting-rec-yes *ngIf="_meetingChildComponents.conMeetingEditRecYes==_currentMeetingChildComponent" [supplierName]="supplierName" [ContractId]="ContractId"  [EngagementId]="engagementId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)" (viewMeetingButtonClick)="handleViewButtonClick($event,2)" [meetingId]="meetingId" ></app-con-edit-meeting-rec-yes>
               <app-con-view-meeting *ngIf="_meetingChildComponents.conMeetingView==_currentMeetingChildComponent" [supplierName]="supplierName" [ContractId]="ContractId" [meetingId]="meetingId" (cancelMeetingButtonClick)="handleCancelAddButtonClick(2)" (editMeetingButtonClick)="handleEditButtonClick($event,2)"> </app-con-view-meeting>
               </p-tabPanel>
               

            </p-tabView>
         </p-tabPanel>


         <p-tabPanel header="Finance"  [hidden]="!viewFinancePrivilege">
            <p-tabView class="fr-tabview__subheader" #myFinanceSubTabView (onChange)="onFinanceSubTabSelect($event)" [activeIndex]="activeFinanceSubTabIndex">

               <p-tabPanel header="Summary">  <app-con-summary-view  [contractId]="ContractId" [PaymentTermName]="PaymentTermName"> </app-con-summary-view></p-tabPanel>
               <p-tabPanel header="Invoices">
                  <app-cont-invoice-list *ngIf="_invoiceChildComponents.contInvoiceList==_currentInvoiceChildComponent"
                     [ContractId]="ContractId" [contractStartDate]="contractStartDate" [SupplierId]="SupplierId"    [SponsorId]="SponsorId"  [EngagementName]="EngagementName" (addButtonClick)="handleInvoiceAddButtonClick()"
                     (editButtonClick)="handleInvoiceEditButtonClick($event)"
                     (viewButtonClick)="handleInvoiceViewButtonClick($event)"> </app-cont-invoice-list>
                  <app-cont-invoice-create *ngIf="_invoiceChildComponents.contInvoiceAdd==_currentInvoiceChildComponent"
                     [ContractId]="ContractId"    [SupplierId]="SupplierId"    [SponsorId]="SponsorId" (calcelInvoiceButtonClick)="handleInvoiceCancelAddButtonClick()"
                     (saveInvoiceButtonClick)="handleInvoiceSaveAddButtonClick()"
                     (viewButtonClick)="handleInvoiceViewButtonClick($event)"></app-cont-invoice-create>
                  <app-cont-invoice-edit *ngIf="_invoiceChildComponents.contInvoiceEdit==_currentInvoiceChildComponent"
                     [ContractId]="ContractId"  [SupplierId]="SupplierId"   [SponsorId]="SponsorId" (calcelInvoiceButtonClick)="handleInvoiceCancelAddButtonClick()"
                     (viewButtonClick)="handleInvoiceViewButtonClick($event)"
                     [invoiceId]="invoiceId"></app-cont-invoice-edit>
                  <app-cont-invoice-view *ngIf="_invoiceChildComponents.contInvoiceView==_currentInvoiceChildComponent"
                     [ContractId]="ContractId" [invoiceId]="invoiceId"  (calcelInvoiceButtonClick)="handleInvoiceCancelAddButtonClick()"
                     (editButtonClick)="handleInvoiceEditButtonClick($event)"></app-cont-invoice-view>
               </p-tabPanel>

               <p-tabPanel header="Draft Invoices">
                  <app-con-accruals-list *ngIf="_accrualChildComponents.contAccrualList==_currentAccrualChildComponent"
                     [contractId]="ContractId" 
                     (editButtonClick)="handleAccrualEditButtonClick($event)" 
                     (viewButtonClick)="handleAccrualViewButtonClick($event)"> </app-con-accruals-list>
                  <app-con-accruals-edit *ngIf="_accrualChildComponents.contAccrualEdit==_currentAccrualChildComponent"
                     [contractId]="ContractId" [accrualId]="accrualId" [SupplierId]="SupplierId"    [SponsorId]="SponsorId"  [EngagementName]="EngagementName"
                     (cancelAccrualButtonClick)="handleAccrualCancelEditButtonClick()" 
                     (viewButtonClick)="handleAccrualViewButtonClick($event)"
                     (saveAccrualButtonClick)="handleAccrualSaveEditButtonClick()"> </app-con-accruals-edit>
                  <app-con-accruals-view *ngIf="_accrualChildComponents.contAccrualView==_currentAccrualChildComponent"
                     [contractId]="ContractId" [accrualId]="accrualId"
                     (editButtonClick)="handleAccrualEditButtonClick($event)" (cancelAccrualButtonClick)="handleAccrualCancelEditButtonClick()" > </app-con-accruals-view>
               </p-tabPanel>


               <p-tabPanel header="Forecast">
                  <app-con-forecast-list
                     *ngIf="_forecastChildComponents.contForecastList==_currentForecastChildComponent"
                     [contractId]="ContractId" (editButtonClick)="handleForecastEditButtonClick($event)"
                     (viewButtonClick)="handleForecastViewButtonClick($event)"> </app-con-forecast-list>
                  <app-con-forecast-edit
                     *ngIf="_forecastChildComponents.contForecastEdit==_currentForecastChildComponent"
                     [contractId]="ContractId" [forecastId]="forecastId"
                     (cancelForecastButtonClick)="handleForecastCancelEditButtonClick()"
                     (viewButtonClick)="handleForecastViewButtonClick($event)"
                     (saveForecastButtonClick)="handleForecastSaveEditButtonClick()" > </app-con-forecast-edit>
               <app-con-forecast-view *ngIf="_forecastChildComponents.contForecastView ==_currentForecastChildComponent"
                     [contractId]="ContractId" [forecastId]="forecastId"
                     (editButtonClick)="handleForecastEditButtonClick($event)"  (cancelForecastButtonClick)="handleForecastCancelEditButtonClick()"> </app-con-forecast-view>
               </p-tabPanel>
            </p-tabView>
         </p-tabPanel>

         <p-tabPanel header="Team" >
            <app-associate-list *ngIf="_teamChildComponents.associateChildList==_currentTeamChildComponent" [FromType]="FromType" [entityId]="ContractId" [SupplierId]="SupplierId"   [SponsorId]="SponsorId" [EngagementId]="engagementId" [contractStartDate]="contractStartDate"  [EngagementName]="EngagementName"  (addTeamButtonClick)="handleAddButtonClick(3)" (editTeamButtonClick)="handleEditButtonClick($event,3)" (viewTeamButtonClick)="handleViewButtonClick($event,3)"> </app-associate-list>
            <app-associate-add-edit *ngIf="_teamChildComponents.associateChildAddEdit==_currentTeamChildComponent" [FromType]="FromType" [actionType]="actionType" [associateId]="associateId" [entityId]="ContractId"  [SupplierId]="SupplierId"   [SponsorId]="SponsorId" [EngagementId]="engagementId" (cancelTeamButtonClick)="handleCancelAddButtonClick(3)" (saveTeamButtonClick)="handleSaveAddButtonClick(3)" (viewTeamButtonClick)="handleViewButtonClick($event,3)"> </app-associate-add-edit>
            <app-associate-view *ngIf="_teamChildComponents.associateChildView==_currentTeamChildComponent" [FromType]="FromType" [associateId]="associateId" [entityId]="ContractId" (editTeamButtonClick)="handleEditButtonClick($event,3)" (cancelTeamButtonClick)="handleCancelAddButtonClick(3)"> </app-associate-view>
           
         </p-tabPanel>
      </p-tabView>
</div>
</body>
</html>
