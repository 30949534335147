<!DOCTYPE html>
<html lang="en">
<head>
  <title> Engagement List</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card flex justify-content-center">
  <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
</div>
<div class="container page-title"> 
  <img src="../../../../assets/images/Loanding-bg.jpg" class="eng-bg__gridlist" alt="Background Image">
  <div class="eng_main__bg">    
    <div [ngClass]="{'nomargin': engagementdata.length === 0}" class="eng-gridview__norecordsection"> 
    <div *ngIf="isTilesEnable">
      <div class="flex-box">
        <div class="fr-flex__col-12">  
        <div class="fr-flex__col-4">
          <h1>Engagements</h1>
        </div>
          <div class="fr-flex__col-8"> 
            <a *ngIf="createPrivilege" routerLinkActive="active" routerLink="/CreateEngagement" class="btn btn-primary pull-right btn-primary-align"> Create Engagement </a>
            
            <a class="btn btn-outline-primary pull-right" id="engListView" (click)="showGridControls()" [ngClass]="{'active-button': isGridEnable}"> <i class="pi pi-list"></i> </a>
            <a class="btn btn-outline-primary pull-right" id="engGridView" (click)="ShowTilesControls()" [ngClass]="{'active-button': isTilesEnable}"> <i class="pi pi-th-large"></i> </a>
            
    </div>
  </div>
  </div>
  <div class="fr-flex__container">
    <div class="fr-flex__col-12" *ngIf="engagementdata.length > 0; else noRecordTemplate">
      <div class="home-container" *ngFor="let engagement of engagementdata">

        <div class="fr-flex__container">
          <div class="fr-flex__col-6">  
        <h1 *ngIf="viewPrivilege; else disabledLink" routerLinkActive="active" (click)="ViewSupplier(engagement)"> {{engagement.engagementName}} </h1>
        <ng-template #disabledLink>
          <h1> {{engagement?.engagementName}} </h1>
        </ng-template>
        </div>
      </div>
        <div class="fr-flex__container">
          <div class="fr-flex__col-4"> 
          <div class="card" (click)="viewPrivilege && ViewSupplier(engagement)">
            <span class="fr-home__content">
              <span class="fr-home__box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/Stakeholders.svg" alt="">
                  </span>
              </span>
              <div class="fr-symbol__content">
                 <div class="fr-symbol__homecontent-primary--text" >
                  {{engagement?.numberOfStakeholders}}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text" >
                  Stakeholders
                 </div>
               </div>
           </span>
          </div>
        </div>
        <div class="fr-flex__col-4"> 
          <div class="card" (click)="viewPrivilege && viewFunction(engagement)">
            <span class="fr-home__content">
              <span class="fr-home__box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/Functions.svg" alt="">
                  </span>
              </span>
              <div class="fr-symbol__content" >
                 <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__homecontent-primary--text">
                 {{engagement?.numberOfGroups}}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text">
                  Functions
                 </div>
               </div>
           </span>
          </div>
        </div>
        <div class="fr-flex__col-4"> 
          <div class="card" (click)="viewPrivilege && viewContract(engagement)">
            <span class="fr-home__content">
              <span class="fr-home__box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/ActiveContracts.svg" alt="" width="54">
                  </span>
              </span>
              <div class="fr-symbol__content" >
                 <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__homecontent-primary--text">
                  {{engagement?.numberOfContracts}}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text">
                  No. of Contracts 
                 </div>
               </div>
           </span>
          </div>
        </div>
        </div>
        <div class="fr-flex__container">
          <div class="fr-flex__col-4"> 
          <div class="card home-m-b-10" (click)="viewPrivilege && viewGovernance(engagement)">
            <span class="fr-home__content">
              <span class="fr-home__box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/ActiveKPI's.svg" width="54" alt="home active img">
                  </span>
              </span>
              <div class="fr-symbol__content" >
                 <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__homecontent-primary--text">
                  {{engagement?.numberOfKPIS}}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text">
                  Active KPIs
                 </div>
               </div>
           </span>
          </div>
        </div>
        <div class="fr-flex__col-4"> 
          <div class="card home-m-b-10" (click)="viewPrivilege && viewFinance(engagement)">
            <span class="fr-home__content">
              <span class="fr-home__box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/TotalEngagementValue.svg" alt="">
                  </span>
              </span>
              <div class="fr-symbol__content" >
                 <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__homecontent-primary--text">
                  {{ getFormattedValue(engagement.totalEngagementValue, engagement.defaultCurrency) }}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text">
                  Total Engagement Value
                 </div>
               </div>
           </span>
          </div>
        </div>
        <div class="fr-flex__col-4"> 
          <div class="card home-m-b-10" (click)="viewPrivilege && viewAssociate(engagement)">
            <span class="fr-home__content">
              <span class="fr-box"> 
                 <span class="for-symbol__box--icon">
                    <img src="../../../../assets/images/OnboardsAssociates.svg" alt="" width="54">
                  </span>
              </span>
              <div class="fr-symbol__content" >
                 <div id="engAddEditFunctionsActiveContracts" class="fr-symbol__homecontent-primary--text">
                  {{engagement?.numberOfAssociates}}
                 </div>
                 <div class="fr-sybmobol__homecontent-secondary-text">
                  Onboard Associates
                 </div>
               </div>
           </span>
          </div>
        </div>
        </div>
      </div>
      </div>
      <!-- Template to show when there are no records -->
  <ng-template #noRecordTemplate>
    <div class="fr-flex__container">
      <div class="fr-flex__col-12">
        <div class="card-norecord">
          <div class="card flex justify-content-center">
            <p-card header="">
              <img src="../../../../assets/images/empty-box.svg" alt="" width="100">
              <p class="m-0">No Record Found</p>
            </p-card>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
      </div>
    </div>
  </div>
</div>

  <div class="eng_mainlist__bg">
<div *ngIf="isGridEnable">
<div class="flex-box"> 
  <div class="fr-flex__container">
      <div class="fr-flex__col-6 custom-pad-left-zero">
          <h1>Engagements</h1>
      </div>
      <div class="fr-flex__col-6 custom-pad-right-zero"> 
        <a *ngIf="createPrivilege" routerLinkActive="active" routerLink="/CreateEngagement" class="btn btn-primary pull-right btn-primary-align"> Create Engagement </a>
        
        <a class="btn btn-outline-primary pull-right" id="engListsView" (click)="showGridControls()" [ngClass]="{'active-button': isGridEnable}"> <i class="pi pi-list"></i> </a>
        <a class="btn btn-outline-primary pull-right" id="engGridsView" (click)="ShowTilesControls()" [ngClass]="{'active-button': isTilesEnable}"> <i class="pi pi-th-large"></i> </a>
     </div>
  </div>
</div>

    <div class="filter-bg" *ngIf="showHideFiltersControls">
      <div class="fr-flex__container">
        <div class="fr-flex__col-3">
            <div class="fr-form__group margin-zero"> 
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i> 
                    <input type="text" class="form-control key-search filter-text" placeholder="Search by Title, ID"> </span>
                 </div>
        </div>
        </div>
      </div> 

            <p-table id="engListTableName" class="primeng-table-custom custom-cells__engagements-list" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="engagementdata" editMode="row" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries" [rowsPerPageOptions]="[10, 20, 50, 100, 200]">
                <ng-template pTemplate="header">
                    <tr>
                        <th id="engListName" pFrozenColumn pSortableColumn="engagementName" pResizableColumn>Engagement Name <p-sortIcon field="engagementName"></p-sortIcon></th>
                        <th id="engListID" pSortableColumn="engagementID" pResizableColumn>Engagement ID <p-sortIcon field="engagementID"></p-sortIcon></th>
                        <th id="engListStartDate" pSortableColumn="engagementStartDate" pResizableColumn pInputText>Engagement Start Date <p-sortIcon field="engagementStartDate"></p-sortIcon></th>
                        <th id="engListNoofContracts" pSortableColumn="numberOfContracts" pResizableColumn class="text-center">No. of Contracts <p-sortIcon field="numberOfContracts"></p-sortIcon></th>
                        <th id="engListNoofFunctions" pSortableColumn="numberOfGroups" pResizableColumn class="text-center">No. of Functions <p-sortIcon field="numberOfGroups"></p-sortIcon></th>
                        <th id="engListTotalEngValue" pSortableColumn="totalEngagementValue" pResizableColumn>Total Engagement Value <p-sortIcon field="totalEngagementValue"></p-sortIcon></th>
                        <th id="engListDefaultCurrency" pSortableColumn="defaultCurrency" pResizableColumn>Default Currency <p-sortIcon field="defaultCurrency"></p-sortIcon></th>
                        <th id="engListEditTH" *ngIf="editPrivilege" class="last-child-width">Edit</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-cols="columns" let-engagementdata let-editing="editing" let-ri="rowIndex">
                    <tr>
                        <td id="engListHyperLink" pFrozenColumn [pTooltip]="engagementdata.engagementName.length > 30 ? engagementdata.engagementName : ''" tooltipPosition="top"> 
                          <a *ngIf="viewPrivilege; else disabledLink" class="table-active-link" (click)="ViewSupplier(engagementdata)"> {{ engagementdata.engagementName}} </a>
                          <ng-template #disabledLink>
                              {{ engagementdata.engagementName}}
                            </ng-template>
                        </td>

                      

                        <td> {{engagementdata.engagementID}} </td>

                        <td>{{ engagementdata.engagementStartDate | date: _dateformart.GridDateFormat }}</td>

                        <td class="grid-center">
                           <a *ngIf="viewPrivilege; else disabledLink1" class="table-active-link" routerLinkActive="active" (click)="viewContract(engagementdata)">{{ engagementdata.numberOfContracts }}</a>
                           <ng-template #disabledLink1>
                            {{ engagementdata.numberOfContracts}}
                          </ng-template>
                          </td>

                        <td class="grid-center">
                           <a *ngIf="viewPrivilege; else disabledLink2" class="table-active-link" routerLinkActive="active" (click)="viewContractGroup(engagementdata)">{{ engagementdata.numberOfGroups }}</a>
                           <ng-template #disabledLink2>
                            {{ engagementdata.numberOfGroups}}
                          </ng-template>
                           </td>
                     
                        <td [pTooltip]="engagementdata.totalEngagementValue ? getFormattedValue(engagementdata.totalEngagementValue, engagementdata.defaultCurrency) : ''" tooltipPosition="top">{{ getFormattedValue(engagementdata.totalEngagementValue, engagementdata.defaultCurrency) }}</td>

                        <td> {{engagementdata.defaultCurrency}} </td>

                        <td id="engListEdit" class="grid-icon-center" *ngIf="editPrivilege"> <span *ngIf="!editing" class="pi pi-pencil" routerLink="/editcomponent" (click)="onRowEditInit(engagementdata)"></span> </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="8" class="text-center"> No Records</td>
                  </tr>
              </ng-template>
               
            </p-table>
</div>
</div>
</div>


</body>
</html>
