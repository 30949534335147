import { Component,ViewChild,OnInit, Input ,Output, EventEmitter } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dateformat , FileExtension, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule} from 'src/app/shared/constants/global.constants';
import { DatePipe, formatDate } from '@angular/common';
import { Router ,ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
import { AbstractControl, FormBuilder, FormGroup, Validators,NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/Loader/loader.service';
import { } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { } from 'rxjs';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import {  } from 'src/app/Governance/kpis/Engagement/Services/engagement-kpi.service';
import { CodeListValues, EngagementAudit, EngagementAuditAttachments, EngagementAuditObsvFinding, EngagementAuditObsvFindingAttachments } from '../../Model/EngagementAudit';

import { EngagementAuditService } from '../../Services/engagement-audit-service.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';


@Component({
  selector: 'app-eng-edit-audit',
  templateUrl: './eng-edit-audit.component.html',
  styleUrls: ['./eng-edit-audit.component.css']
})
export class EngEditAuditComponent  implements  OnInit{

  firstLevelPopupVisible: boolean = false;
  secondLevelPopupVisible: boolean = false;
  thirdLevelPopupVisible: boolean = false;

  openFirstLevelPopup() {
    this.firstLevelPopupVisible = true;
  }

  openSecondLevelPopupEdit() {
    this.firstLevelPopupVisible = false; // Close the first popup
    this.secondLevelPopupVisible = true;
    this.isUpdatedFlag=false;
   this.header='Add Finding';
    this.isStatusClosedForSupplier=false;
    this.isStatusClosedForDecision=false;
    this.engagementAuditObsvFindingData=new EngagementAuditObsvFinding();
    
    this.findingSubmitFlag=false;
    this.engagementFindingForm.reset();
    this.engagementAuditObsvFindingAttachments=[];
    this.uploadedFindingFileData=[];
    this.editFindingIndex=-1;
    this.isAddFindingFileUploadButtonDisabled = false;
    this.oldFindingModel=JSON.stringify(this.engagementAuditObsvFindingData);
  }

  openThirdLevelPopup() {
    this.secondLevelPopupVisible = false; // Close the second popup
    this.thirdLevelPopupVisible = true;
  }
  
  visible: boolean = false;

  showDialog() {
      this.visible = true;
  }


  date: Date | undefined;
  value: string | undefined;
  @ViewChild('myEngagementAuditForm') childForm: NgForm | undefined;
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  @Output() cancelAuditButtonClick = new EventEmitter<void>();
  @Output() saveAuditButtonClick = new EventEmitter<void>();
  @Output() viewButtonClick: EventEmitter<any> = new EventEmitter<any>();
  // @Input() supplierId: any;
  @Input() engagementId: any;
  @Input() supplierName: any;
  @Input() auditId: any;
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];
  _enumBreadcrumb :any;
  _dateformart :any;
  auditStatusDataSource: CodeListValues[] | undefined;
  auditAuditeeSPOCDataSource:any[]=[];
  observationStatusDataSource: CodeListValues[] | undefined;
  observationGradesDataSource: CodeListValues[] | undefined;
  
  myEngagementAuditForm: FormGroup;
  // supplierData: any;
 
  // _supplierAudit=new SupplierAudit();
  // _supplierAuditAttachment = new SupplierAuditAttachments();
  // _lstSupplierAuditAttachments: SupplierAuditAttachments[] = [];
  engagementData: any;
  supplierContactData: any;
  _engagementAudit=new EngagementAudit();
  _engagementAuditAttachment = new EngagementAuditAttachments();
  _lstEngagementAuditAttachments: EngagementAuditAttachments[] = [];
  submitted = false;
  uploadedFiles: File[] = [];
  maxFileSize: any
  timeStamp: any;
  formData = new FormData();
  uploadedAuditFile: any;
  newAttachment: any;
  uploadedFileName: any;
  allowedExtensions: any;
  currentFileSize: any;
  multiAuditFileData: any[] = [];
  auditMinEndDate:any;
  auditMaxStartDate:any;
  isUploadButtonDisabled:boolean=false;
  isAddFindingFileUploadButtonDisabled:boolean=false;
  createdBy: any;
  updatedBy: any;

  //--Finding Variables-----------
  // engagementFindingForm: FormGroup;
  // supplierAuditObsvFindingData=new SupplierAuditObsvFinding();
  // supplierFindingAttachmentItem = new SupplierAuditObsvFindingAttachments();
  // supplierAuditObsvFindingAttachments: SupplierAuditObsvFindingAttachments[] = [];
  // findingListDataSource:SupplierAuditObsvFinding[] = [];;
  // findingItem=new SupplierAuditObsvFinding();

  engagementFindingForm: FormGroup;
  engagementAuditObsvFindingData=new EngagementAuditObsvFinding();
  engagementFindingAttachmentItem = new EngagementAuditObsvFindingAttachments();
  engagementAuditObsvFindingAttachments: EngagementAuditObsvFindingAttachments[] = [];
  findingListDataSource:EngagementAuditObsvFinding[] = [];;
  findingItem=new EngagementAuditObsvFinding();
findingSubmitFlag=false;
isStatusClosedForSupplier=false;
isStatusClosedForDecision=false;
todayDate:any;
uploadedFindingFile: any;
uploadedFindingFileData: any[] = [];
findingFormData = new FormData();
multiFindingFileData: any[] = [];
header:string='Add Finding';

  _oldEngagementAudit:any;
  //observation grid
  _auditObservations: any[] = [];
  _addObservationFilesMandatory: boolean = false;
  uploadedFileNames: string = "";
  isSaveBtnDisable:boolean=false;
  constructor(private router: Router, private fb: FormBuilder,
    
     private confirmationService: ConfirmationService, 
     private messageService: MessageService,
      private loaderService: LoaderService,
       private breadcrumbService: BreadcrumbService,private masterDataService: SponserService,
      private _engagementAuditService: EngagementAuditService,
      private activeRouter: ActivatedRoute,private _fileService: FileUploadService,private datePipe: DatePipe,private auditLogService: AuditLogService,private privilegeService: PrivilegeService,private engagementService: EngagementService,private _mastersupplierService: MasterSupplierService){
      this.myEngagementAuditForm = this.fb.group({
      _auditTitle:['', [Validators.required,Validators.maxLength(50),this.notOnlySpacesValidator]],
      _auditNumber:['', [Validators.required,Validators.maxLength(15),this.notOnlySpacesValidator,Validators.pattern(/^\S*$/)]],
      _auditor:['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]{1,50}$/),this.notOnlySpacesValidator]],
      _auditeeSPOC:['', [Validators.required]],
      _auditStartDate:['',[Validators.required]],
      _auditEndDate:['',[Validators.required]],
      _status:['', [Validators.required]],
      _description:['',[Validators.maxLength(300),this.notOnlySpacesValidator]],	
      _auditFile: [''], 
       auditFileName: '',
     
    });


    //---finding form
    this.engagementFindingForm = this.fb.group({
      _findingId:['', [Validators.required,Validators.maxLength(15),this.notOnlySpacesValidator]],
      _findingAreaId:['', [Validators.required]],
      _findingDetail:['', [Validators.required,Validators.maxLength(300)]],
      _findingOwnerId:['', [Validators.required]],
      _targetDate:['',[Validators.required]],
      // _supplierResponse:['', [this.isStatusClosed ? Validators.required : null,Validators.maxLength(300)]],
      _supplierResponse:['', [Validators.maxLength(300)]],
      _evidenceOfClosure:['', [Validators.maxLength(300)]],
      // _decisionOfReview:['', [this.isStatusClosed ? Validators.required : null,Validators.maxLength(300)]],	
      _decisionOfReview:['', [Validators.maxLength(300)]],	
      _statusMappingId: ['', [Validators.required]],
      _findingFile: [''], findingFileName: '',
      _findingAttachments: [''],
    });
  }; 

  auditIdentifierDetails:any;
  auditEntities:any;
  auditOperations:any;
  auditModule:any;

  ngOnInit() {  
   
     this.loaderService.showLoader();
     this.loaderService.hidefinalLoader();
    this._dateformart=Dateformat;
    this.auditIdentifierDetails=AuditIdentifier; 
    this.auditEntities=AuditLogEntities;
    this.auditOperations=AuditOperations;
    this.auditModule=AuditLogModule;

    
    this.GetEngagementDataById(this.engagementId);
    this.GetAllAuditStatus(3);
    this.GetAllObservationsStatus(3);
    this.GetAllEditAuditeeSPOCs();
    this.GetAllObservationGrades(14);
    this.todayDate = new Date();
    this.GetFindingArea();
    this.GetFindingStatusStatus(3);
   
}


GetAllAuditStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Audit Status').subscribe(
    res=>{
      this.auditStatusDataSource=res.data;
    }
  );
};
GetAllObservationsStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Observation Status').subscribe(
    res=>{
      this.observationStatusDataSource=res.data;
    }
  );
};
GetAllEditAuditeeSPOCs() {
    this.engagementService.GetEngagementById(this.engagementId)
    .subscribe(res => {  
      this.engagementData = res.data;  
      this.GetEditSupplierContacts(res.data.supplierId);
    
     
     
    });
}
GetEditSupplierContacts(Id:number){
 
  this._mastersupplierService.getSupplierContactsDataById(Id)
  .subscribe(res=>{
    this.supplierContactData = res.data;
    if (this.engagementData != undefined &&  this.supplierContactData != undefined) {
      this.auditAuditeeSPOCDataSource = this.supplierContactData.map((obj: {contactName: any; id: any; }) => ({ name: `${obj.contactName} ( ${this.supplierName} )`,id:obj.id }));
    }
  })
}

GetAllObservationGrades(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
    this.observationGradesDataSource=res.data;
     this.getEngagementAuditById(this.auditId);
    }
  );
};



generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
{
  this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
}

getBreadcrumbs(): MenuItem[] {
  return this.breadcrumbService.getBreadcrumbs();
}
onCancelEngagementAudit()
{
  
  if ((this._oldEngagementAudit!=JSON.stringify(this._engagementAudit)))
  {
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
        const dataResult = {
          engagementId:this.engagementId,
          auditId:parseInt(this.auditId),
        };
        this.viewButtonClick.emit(dataResult);
        },
        reject: () => {
        },
      });
  }
  else{
    this.cancelAuditButtonClick.emit();
  }

   
}

onUpdateEngagementAudit(){
//validate child grid form fileds
this.isSaveBtnDisable = true;
setTimeout(() => {
  this.isSaveBtnDisable = false;
}, 3000);
this._addObservationFilesMandatory = this._auditObservations.some((Actions: any) => {
  if (Actions.observationId == "") {
    return true;       
  }
  return false;    
}); 
  this.submitted = true;
  

  if(this.myEngagementAuditForm.valid && !this._addObservationFilesMandatory)
  {
    this.loaderService.showLoader();

     
    this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {        
      if (response) {
       
          //Call UploadMultipleFile method again for Finding form attachments
          this._fileService.UploadMultipleFile(this.findingFormData).subscribe((response: any) => {        
           if (response) 
            {
              if (this.findingFilesToBeRemoved.length > 0) {
    
                this._fileService.DeleteFile(this.findingFilesToBeRemoved).subscribe(
                  res => {
             
                    this.EngagementAuditUpdate();
                  });
                }
                else{
                  this.EngagementAuditUpdate();
                }
             
            }
            else 
            {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add engagement audit observation finding file(s) failed to save', detail: '' });
            }
          })
      }
      else {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Add engaegment audit file(s) failed to save', detail: '' });
      }
    })
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }
  
}
notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.trim() === '') {
     return { 'onlySpaces': true };
   }
   return null;
 }

 FileChange(event: any) {
  const files: FileList = event.target.files;
  if (files.length <= 1) {
    this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
    if (files.length > 0) {
      this.currentFileSize = files[0].size;
      this.uploadedFileName = files[0].name;
      this.allowedExtensions = [FileExtension.DOC, FileExtension.DOCX, FileExtension.PDF, FileExtension.XLS, FileExtension.XLSX];
      var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
    }
    // Check if the uploaded file's extension is allowed
    if (this.allowedExtensions.includes(uploadedExtension)) {
      if (this.currentFileSize <= this.maxFileSize) {
        if (this.uploadedFiles.length + files.length <= 3) {
          for (let i = 0; i < files.length; i++) {
            this.uploadedFiles.push(files[i]);
            this.myEngagementAuditForm.patchValue({
              addMeetingAttachments: files[0].name,
            });
            this.uploadedAuditFile = files[0];
            this.multiAuditFileData.push(this.uploadedAuditFile);
           this.timeStamp = "";
           var utcDateTimeNow = new Date().toISOString();
           this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
           //this.formData.append("files[]", file, fileName);
            this.formData.append("file", this.uploadedAuditFile, "Governance/Audit/" + this.timeStamp + "_" + this.uploadedAuditFile.name);
            this._engagementAuditAttachment.fileName = this.uploadedAuditFile.name;
            this._engagementAuditAttachment.filePath = "Governance/Audit/" + this.timeStamp + "_" + this._engagementAuditAttachment.fileName;
           
            this.newAttachment = {
              fileName: this._engagementAuditAttachment.fileName,
              filePath: this._engagementAuditAttachment.filePath
                  };
            this._lstEngagementAuditAttachments.push(this.newAttachment);
          }
        } else {
          this.myEngagementAuditForm.patchValue({
      

          });

          this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
        }
      }
      else {
        this.myEngagementAuditForm.patchValue({
          auditFileName: '',
          _auditFile: ''
        });
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
      }
    }
    else {
      this.myEngagementAuditForm.patchValue({
        auditFileName: '',
        _auditFile: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }

  }
  else {
    this.myEngagementAuditForm.patchValue({
      auditFileName: '',
      _auditFile: ''
    });
    this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

  }
  if(this.uploadedFiles.length >= 3)
  {
    this.isUploadButtonDisabled = true;
  }
  else{
    this.isUploadButtonDisabled = false;
  }
}

removeFile(file: File) {
  const index = this.uploadedFiles.indexOf(file);
  if (index !== -1) {
    this.uploadedFiles.splice(index, 1);

    const attachmentIndex = this._lstEngagementAuditAttachments.findIndex(attachment => attachment.fileName == file.name);
    if (attachmentIndex !== -1) {
      this._lstEngagementAuditAttachments.splice(attachmentIndex, 1);

      this.myEngagementAuditForm.patchValue({
        auditFileName: '',
        _auditFile: ''
      }); 
    }
  }
  if(this.uploadedFiles.length >= 3)
    {
      this.isUploadButtonDisabled = true;
    }
    else{
      this.isUploadButtonDisabled = false;
    }
}


EngagementAuditUpdate() {
  this._engagementAudit.createdBy=this.privilegeService.getLoginUserId();
  this._engagementAudit.updatedBy=this.privilegeService.getLoginUserId();
  this._engagementAudit.auditAttachments = this._lstEngagementAuditAttachments;
  this._engagementAudit.auditObservations=this._auditObservations
  this._engagementAudit.engagementId = this.engagementId;
  this._engagementAudit.auditStartDate =  this.datePipe.transform(this._engagementAudit.auditStartDate, "MM/dd/yyyy");
  this._engagementAudit.auditEndDate =  this.datePipe.transform(this._engagementAudit.auditEndDate, "MM/dd/yyyy");
 

  this._engagementAudit?.auditObservations?.forEach((observation:any)=>{

    observation?.engagementAuditObservationFindingList?.forEach( (finding:any) => {
      finding.targetDate =  this.datePipe.transform(finding.targetDate, "MM/dd/yyyy");
      
    });
  });



 
  this._engagementAuditService.UpdateEngagementAudit(this._engagementAudit).subscribe(
    res => {
      if(res.status){
        this.loaderService.hideLoader();
        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
        this.compareModels();
        this.saveAuditButtonClick.emit();
        const dataResult = {
          engagementId:this.engagementId,
          auditId:this.auditId,
        };
        this.viewButtonClick.emit(dataResult);
      }
    }
  );
}

onStartDateSelection(startDate:any){

  this.auditMinEndDate= new Date(startDate);
  this.auditMinEndDate.setDate(this.auditMinEndDate.getDate() + 1);

}
onEndDateSelection(endDate:any){
  if(endDate!=null){
  this.auditMaxStartDate= new Date(endDate);
  this.auditMaxStartDate.setDate(this.auditMaxStartDate.getDate() - 1);
}
else{
  this.auditMaxStartDate="";
}
}


// ----------Start-------Namita------------------Finding popup related code---------------------
findingAreaData:any;
GetFindingArea() {
  this.masterDataService.getCodeListData(15,'').subscribe(
    res=>{
      this.findingAreaData=res.data;
    }
  );
};

findingStatusData:any;
GetFindingStatusStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Finding Status').subscribe(
    res=>{
      this.findingStatusData=res.data;
    }
  );
};
isSaveFindingPopupPopupBtnDisable = false;

saveFinding()
{
  this.isSaveFindingPopupPopupBtnDisable = true;
  setTimeout(() => {
    this.isSaveFindingPopupPopupBtnDisable = false;
  }, 3000);
  this.findingSubmitFlag=true;
if(!this.isStatusClosedForSupplier){
  this.engagementFindingForm.controls['_supplierResponse'].setErrors(null);
}
else{
  this.engagementFindingForm.controls['_supplierResponse'].setErrors({required: true});
}


if(!this.isStatusClosedForDecision){
   
    this.engagementFindingForm.controls['_decisionOfReview'].setErrors(null);
  }
else{
   
    this.engagementFindingForm.controls['_decisionOfReview'].setErrors({required: true});
  }

  if(this.engagementFindingForm.valid)
  {
    this.saveFindingDetails();
  }
  else{
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
  }

}


saveFindingDetails()
{
    var statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
    var OwnerName = this.auditAuditeeSPOCDataSource.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.findingOwnerId)?.name;
    this.engagementAuditObsvFindingData.findingStatus=statusName;
    this.engagementAuditObsvFindingData.findingOwnerName=OwnerName;
    this.engagementAuditObsvFindingData.isDeleted=false;

    this.engagementAuditObsvFindingData.targetDate =  this.datePipe.transform(this.engagementAuditObsvFindingData.targetDate, "MM/dd/yyyy");


    this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
    

    var existing=this.findingListDataSource[this.editFindingIndex];
    if(existing == undefined)//Add case
    {
      this.findingCreatedBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.createdDate=new Date();
      this.findingListDataSource.unshift(this.engagementAuditObsvFindingData);
    }
    else//Edit case
    {
     this.engagementAuditObsvFindingData.createdBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.updatedBy=this.privilegeService.getLoginUserId();
      this.engagementAuditObsvFindingData.updatedDate=new Date();
      this.engagementAuditObsvFindingData.id=existing.id;
      this.findingListDataSource[this.editFindingIndex]=this.engagementAuditObsvFindingData;
    }
    this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList=this.findingListDataSource;
    this._auditObservations[this.selectedObservationIndex].findingsCount=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length
    this.engagementAuditObsvFindingData=new EngagementAuditObsvFinding();
    this.secondLevelPopupVisible=false;
    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Finding has been added', detail: '' });
    this.loaderService.hideLoader();
}

cancelFinding(){
 
 if(this.oldFindingModel==JSON.stringify(this.engagementAuditObsvFindingData))
 {
  this.secondLevelPopupVisible=false;  
 }
 else{
  this.confirmationService.confirm({
    header: 'Confirm',
    message: 'Changes will not be saved, Do you want to Continue?',
    accept: () => {
    this.messageService.add({key: 'tc', severity: 'warn', summary: 'Changes not saved', detail: '',});
    this.secondLevelPopupVisible=false;  
  }
  });
 }
 }

isStatusClosed:boolean=false;
findingStatusChangeClick(event:any){
  var statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
  if(statusName == 'Closed')
  {
    this.isStatusClosed=true;
    if(this.engagementAuditObsvFindingData.engagementResponse == "" || this.engagementAuditObsvFindingData.engagementResponse == undefined)
    {
      this.isStatusClosedForSupplier=true;
    }
  
    if(this.engagementAuditObsvFindingData.decisionOfReview == "" || this.engagementAuditObsvFindingData.decisionOfReview == undefined)
    {
      this.isStatusClosedForDecision=true;
    }
  }
  else{
    this.isStatusClosedForSupplier=false;
  this.isStatusClosedForDecision=false;
  this.isStatusClosed=false;
  }
  }


  engagementResponseChange(){
    if(this.engagementAuditObsvFindingData.statusMappingId != undefined)
    {
      var statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
    if(this.engagementAuditObsvFindingData.engagementResponse == "" && statusName == 'Closed'){
    this.isStatusClosedForSupplier=true;
  }
  else{
    this.isStatusClosedForSupplier=false;
  }
    }
  }
  
  
  decisionReviewChange(){
    if(this.engagementAuditObsvFindingData.statusMappingId != undefined)
    {
      var statusName = this.findingStatusData.find((aa: EngagementAuditObsvFinding) => aa.id === this.engagementAuditObsvFindingData.statusMappingId)?.name;
    
    if(this.engagementAuditObsvFindingData.decisionOfReview == ""  && statusName == 'Closed')
    {
    this.isStatusClosedForDecision=true;
    }
    else{
    this.isStatusClosedForDecision=false;
    }
  }
  }
  

onFindingFileChange(event: any) {
  const files: FileList = event.target.files;
  if (files.length <= 1) {
    this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
    if (files.length > 0) {
      this.currentFileSize = files[0].size;
      this.uploadedFileName = files[0].name;
      var uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
    }
    // Check if the uploaded file's extension is allowed
    if (this._fileService.allowedExtensions.includes(uploadedExtension)) {
      if (this.currentFileSize <= this.maxFileSize) {
        if (this.uploadedFindingFileData.length + files.length <= 3) {
          for (let i = 0; i < files.length; i++) {
           
            this.uploadedFindingFileData.push(files[i]);
            this.engagementFindingForm.patchValue({
              _findingAttachments: files[0].name,
            });
            this.uploadedFindingFile = files[0];
            this.multiFindingFileData.push(this.uploadedFindingFile);
           this.timeStamp = "";
           var utcDateTimeNow = new Date().toISOString();
           this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
            this.findingFormData.append("file", this.uploadedFindingFile, "Governance/Audit/Finding/" + this.timeStamp + "_" + this.uploadedFindingFile.name);
            this.engagementFindingAttachmentItem.fileName = this.uploadedFindingFile.name;
            this.engagementFindingAttachmentItem.filePath = "Governance/Audit/Finding/" + this.timeStamp + "_" + this.engagementFindingAttachmentItem.fileName;
           
            this.newAttachment = {
                    fileName: this.engagementFindingAttachmentItem.fileName,
                    filePath: this.engagementFindingAttachmentItem.filePath
                    //isFileExist:this.supplierFindingAttachmentItem.isFileExist
                  };
            this.engagementAuditObsvFindingAttachments.push(this.newAttachment);
            this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
          }
        } else {
          this.engagementFindingForm.patchValue({
            _findingFile: '',
            findingFileName: ''

          });

          this.messageService.add({ key: 'tc', severity: 'error', summary: 'Maximum 3 files allowed.', detail: '' });
        }
      }
      else {
        this.engagementFindingForm.patchValue({
          findingFileName: '',
          _findingFile: ''
        });
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
      }
    }
    else {
      this.engagementFindingForm.patchValue({
        findingFileName: '',
          _findingFile: ''
      });
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }

  }
  else {
    this.engagementFindingForm.patchValue({
      findingFileName: '',
        _findingFile: ''
    });
    this.messageService.add({ key: 'tc', severity: 'error', summary: 'Select and upload 1 file at a time.', detail: '' });

  }
  if(this.uploadedFindingFileData.length >= 3)
  {
    this.isAddFindingFileUploadButtonDisabled = true;
  }
  else{
    this.isAddFindingFileUploadButtonDisabled = false;
  }
}

findingFilesToBeRemoved:any=[];
removeFindingFile(file: File) {
  const index = this.uploadedFindingFileData.indexOf(file);
  if (index !== -1) {
    var filedetail=this.uploadedFindingFileData[index];
    this.uploadedFindingFileData.splice(index, 1);

    const attachmentIndex = this.engagementAuditObsvFindingAttachments.findIndex(attachment => attachment.fileName === file.name);
    if (attachmentIndex !== -1) {
      this.engagementAuditObsvFindingAttachments.splice(attachmentIndex, 1);
    }

  
    this.engagementAuditObsvFindingData.observationFindingAttachment=this.engagementAuditObsvFindingAttachments;
    this.engagementFindingForm.patchValue({
      _findingFile: '',
      findingFileName: ''
    })


    if(filedetail.isFileExist){
      var findingFileToDelete = {
         "fileName": filedetail.name,
         "filePath": filedetail.filePath
       };
       this.findingFilesToBeRemoved.push(findingFileToDelete);
     }
  }
  if(this.uploadedFindingFileData.length >= 3)
  {
    this.isAddFindingFileUploadButtonDisabled = true;
  }
  else{
    this.isAddFindingFileUploadButtonDisabled = false;
  }
}




addFindingInObservation(){
  this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList=this.findingListDataSource;
  this._auditObservations[this.selectedObservationIndex].findingsCount=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length
  this.visible = false;
}

cancelFindingList(){
  
  
  this.visible = false;
  this.findingListDataSource=[];
}

editFindingIndex:number=0;
isUpdatedFlag=false;
oldFindingModel:any;
findingCreatedBy:any;
findingUpdatedBy:any;
editFinding(editFindingIndex: number){
  this.header='Edit Finding';
  this.uploadedFindingFileData=[];
  this.engagementAuditObsvFindingAttachments=[];
  this.isUpdatedFlag=true;
  this.editFindingIndex=editFindingIndex;
  var findingRecord=this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[editFindingIndex];
  this.engagementAuditObsvFindingData.findingId=findingRecord.findingId;
  this.engagementAuditObsvFindingData.findingOwnerId=findingRecord.findingOwnerId;
  this.engagementAuditObsvFindingData.findingAreaId=findingRecord.findingAreaId;
  this.engagementAuditObsvFindingData.targetDate=new Date(findingRecord.targetDate);
  this.engagementAuditObsvFindingData.statusMappingId=findingRecord.statusMappingId;
  this.engagementAuditObsvFindingData.findingDetail=findingRecord.findingDetail;
  this.engagementAuditObsvFindingData.engagementResponse=findingRecord.engagementResponse;
  this.engagementAuditObsvFindingData.evidenceOfClosure=findingRecord.evidenceOfClosure;
  this.engagementAuditObsvFindingData.decisionOfReview=findingRecord.decisionOfReview;
  this.findingCreatedBy=this.privilegeService.getUserName(findingRecord.createdBy);
  this.engagementAuditObsvFindingData.createdDate=findingRecord.createdDate;
  this.engagementAuditObsvFindingData.updatedDate=findingRecord.updatedDate;
  this.findingUpdatedBy=this.privilegeService.getUserName(findingRecord.updatedBy);
  this.engagementAuditObsvFindingAttachments=findingRecord.observationFindingAttachment;
  if(findingRecord.observationFindingAttachment != null && findingRecord.observationFindingAttachment.length>0){
    let filedata;
    
    findingRecord.observationFindingAttachment.forEach((file:any) => {
      filedata = {
        name: file.fileName,
        filePath:file.filePath,
        isFileExist:file.isFileExist
      };
      this.uploadedFindingFileData.push(filedata);
      
    });
    this.secondLevelPopupVisible = true;
  }
  else{
    this.secondLevelPopupVisible = true;
  }
this.oldFindingModel=JSON.stringify(this.engagementAuditObsvFindingData);
if(this.uploadedFindingFileData.length >= 3)
{
  this.isAddFindingFileUploadButtonDisabled = true;
}else{
  this.isAddFindingFileUploadButtonDisabled = false;
}
}
// ----------End-------Namita------------------Finding popup related code---------------------

formOldEngagementAudit()
{
  this._oldEngagementAudit=JSON.stringify(this._engagementAudit);
}

//abservation operations
AddObservations(){ 
  this._auditObservations.unshift({
    id: 0,
    observationId: '',
    findingsCount:0,
    grade: 0,
    statusMappingId:0,
  });
}

deleteObservation(currentObservationIndex: number) {  
  this.confirmationService.confirm({
       header: 'Confirm',
       message: 'Are you sure you want to delete this observation?',//+" "+ addmeeting.ActionName + '?',
       accept: () => {
       this._auditObservations.splice(currentObservationIndex, 1);
       this.messageService.add({ severity: 'success', summary: 'Audit Observation deleted', detail: '',});
       }
     });
   }

 

   selectedObservationIndex:any;
  observationId:any;

   GetFindinglist(selectedIndex:any)
   {
        this.selectedObservationIndex=selectedIndex;
        this.observationId=this._auditObservations[this.selectedObservationIndex].observationId;
        if(this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length>0)
        {
        this.findingListDataSource=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList;
       
       
        this.findingListDataSource.forEach((finding:any) => {
          if(finding.observationFindingAttachment != null){
          finding.observationFindingAttachment.forEach((file:any)=>{
            file.isFileExist=true;
          });
        }
          
        });
      }
        else{
        this.findingListDataSource=[];
        }
        this.visible = true;
   }
 

   selectedObservationId:any;
   



   viewFindingList(selectedIndex:any){
    
    this.selectedObservationId = "";
    this.selectedObservationIndex=selectedIndex;
    this.selectedObservationId = this._auditObservations[this.selectedObservationIndex].observationId;
    if(this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.length>0){
    this.findingListDataSource=this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList;
    this.findingListDataSource.forEach((finding:any) => {
      if(finding.observationFindingAttachment != null && finding.observationFindingAttachment.length>0){
      finding.observationFindingAttachment.forEach((file:any)=>{
        file.isFileExist=true;
      });
    }
      
    });
  }
    
    this.showFindingListPopup();
}
showFindingListPopup()
{
 this.visible = true;
}

closeFindingListPopup(){
  this.visible = false;
}

deleteFinding(currentFindingIndex: number) {  
    this.confirmationService.confirm({
         header: 'Confirm',
         message: 'Are you sure you want to delete this finding?',
         accept: () => {
          if(this._auditObservations[this.selectedObservationIndex]?.id<=0 || this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[currentFindingIndex].id<=0)
              this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList.splice(currentFindingIndex, 1);
          else{
            this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[currentFindingIndex].isDeleted=true;
                // call the method to delete the attachments added into the finding
           this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[currentFindingIndex].observationFindingAttachment.forEach( (findingAttachment:any, findingAttachmentIndex:any) => {
              if(findingAttachment.isFileExist){
                var findingFileToDelete = {
                   "fileName": findingAttachment.name,
                   "filePath": findingAttachment.filePath
                 };
                 this.findingFilesToBeRemoved.push(findingFileToDelete);
               }
               this._auditObservations[this.selectedObservationIndex].engagementAuditObservationFindingList[currentFindingIndex].observationFindingAttachment.splice(findingAttachmentIndex, 1);
            });
          }
         var findnigDetail =this._auditObservations[this.selectedObservationIndex]?.engagementAuditObservationFindingList?.filter((t: { isDeleted: any; })=>t.isDeleted!=true);
         this._auditObservations[this.selectedObservationIndex].findingsCount =  (findnigDetail.length>0)?findnigDetail.length:0
         this.messageService.add({ severity: 'success', summary: 'Audit finding deleted', detail: '', });
         }
       });
}

engagementAuditDetail:any;
   //Edit Operations
   getEngagementAuditById(auditId:number){
    this.loaderService.showLoader();
      this._engagementAuditService.getEngagementAuditById(auditId).subscribe(
      response=>{
       this._engagementAudit=response.data;
       this.engagementAuditDetail=response.data;
       this.createdBy =this.privilegeService.getUserName(response.data.createdBy);
       this.updatedBy=this.privilegeService.getUserName(response.data.updatedBy);
       this._engagementAudit.auditStartDate=new Date(response.data.auditStartDate);
       this._engagementAudit.auditEndDate=new Date(response.data.auditEndDate);
       this._auditObservations=response.data.auditObservations;
       this._lstEngagementAuditAttachments=response.data.auditAttachments;
       this.uploadedFiles = response.data.auditAttachments.map((attachment: { fileName: any; }) => {
         return new File([attachment.fileName], attachment.fileName);
       });
       this.uploadedFileNames = this.uploadedFiles.map(file => file.name).join(', ');

       if(this.engagementDetail!=undefined && this.engagementDetail !=null)
       {
         var engagementName = this.engagementDetail.engagementName;
         var engaementData =this.engagementDetail;
         if(this.supplierContactData!= undefined && this.supplierContactData.length>0){

          var supplierContactName = this.supplierContactData.filter((t: { id: any; })=>t.id==this.engagementAuditDetail.auditeeSPOC);
          if(supplierContactName != undefined && supplierContactName.length>0){
            this.engagementAuditDetail.auditee = supplierContactName[0].contactName+" ("+this.supplierName+")";
          } 

          this.engagementAuditDetail?.auditObservations?.forEach( (observation:any) => {
            observation?.engagementAuditObservationFindingList.forEach( (finding:any) => {
             
              
              
              var supplierContactName = this.supplierContactData.filter((s: { id: any; })=>s.id== finding.findingOwnerId);
              if(supplierContactName != undefined && supplierContactName.length>0){
                finding.findingOwnerName = supplierContactName[0].contactName+" ("+this.supplierName+")";                
              } 
            });
          });
         }
       }
       this.oldEngagementAuditData=JSON.stringify(this._engagementAudit);
       this.engagementAuditDetailBeforeUpdate=JSON.stringify(this._engagementAudit);
       if(this.uploadedFiles.length >= 3)
       {
        this.isUploadButtonDisabled = true;
       }     
       this.formOldEngagementAudit();
      }
    );
    this.loaderService.hideLoader();
  }
  engagementDetail:any;
  GetEngagementDataById(engagementId:number){
    this.engagementService.GetEngagementById(this.engagementId).subscribe(
      res=>{
        this.engagementDetail=res.data;
       this.getEngagementAuditById(this.auditId)
     
      }
    );
  }
  changedEngagementAuditBasicInfo:any;
    oldEngagementAuditData:any;
    engagementAuditDetailBeforeUpdate:any
    compareModels() {

      this._engagementAudit.auditStartDate = new DatePipe('en-US').transform(new Date(this._engagementAudit.auditStartDate), this._dateformart.AuditLogFormat);
      this._engagementAudit.auditEndDate = new DatePipe('en-US').transform(new Date(this._engagementAudit.auditEndDate ), this._dateformart.AuditLogFormat);

      this.engagementAuditDetailBeforeUpdate=JSON.parse(this.engagementAuditDetailBeforeUpdate);
      this.engagementAuditDetailBeforeUpdate.auditStartDate = new DatePipe('en-US').transform(new Date(this.engagementAuditDetailBeforeUpdate.auditStartDate), this._dateformart.AuditLogFormat);
      this.engagementAuditDetailBeforeUpdate.auditEndDate = new DatePipe('en-US').transform(new Date(this.engagementAuditDetailBeforeUpdate.auditEndDate ), this._dateformart.AuditLogFormat);

      this.engagementAuditDetailBeforeUpdate.engagementId=this.engagementAuditDetailBeforeUpdate.engagementId.toString();
      this.engagementAuditDetailBeforeUpdate=JSON.stringify(this.engagementAuditDetailBeforeUpdate);

      this.changedEngagementAuditBasicInfo = this.auditLogService.compareModels(JSON.parse(this.engagementAuditDetailBeforeUpdate),this._engagementAudit,this.auditEntities.EngagementAudits,this.auditIdentifierDetails.EngagementAudits,this.auditOperations.Update,this.auditModule.GovernanceService);
    }
}
